import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";
import { API_URL } from '../../features/global.js'
import TableComponent from './TableComponent.jsx'
import ModalForm from '../../components/Modal'

import axios from "axios";
import "./styles.css";


export default function DataKeluar() {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [dataDtl, setDataDtl] = useState([])
    const [onMOdal, setOnModal] = useState(false)
    useEffect(() => {
        onLoad()
    }, [])
    const tutup = () => {
        setDataDtl(null)
        setOnModal(false)
    }
    const onLoad = () => {
        axios
            .get(`${API_URL}item/findKeluar`, {})
            .then(function (response) {
                if (response.status == 200) {
                    setData(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const onLihat = (obj) => {
        // console.log(obj);
        axios
            .get(`${API_URL}item/findOneKeluar?id=${obj.transaksi_keluar_id}`, {})
            .then(function (response) {
                if (response.status == 200) {
                    // console.log(response.data,'--');
                    setDataDtl(response.data)
                    setOnModal(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const extras = () => {
        return (
            <Row>
                <Col>
                    <Button type="primary" primary onClick={()=>{
                        navigate(`../barangkeluar/add`)
                    }}>Tambah Data</Button>
                </Col>
            </Row>
        )
    }
    const isimodal = () => (
        (dataDtl.length > 0) &&
        <>
            <Row style={{ padding: 10,backgroundColor:'#e3e3e3' }}>
                <Col span={7}><b>Kode Item</b></Col>
                <Col span={12}><b>Nama Item</b></Col>
                <Col span={5}><b>Request</b></Col>
            </Row>
            {
                dataDtl.map((o, index) => {
                    return (
                        <Row style={{ padding: 10 }}>
                            <Col span={7}>{o.kode_item}</Col>
                            <Col span={12}>{o.nama_barang}</Col>
                            <Col span={5}>{o.qty}</Col>
                        </Row>
                    )
                })
            }
        </>

    )
    return (
        <div>
            {
                (onMOdal) &&
                <ModalForm width={800} content={isimodal()} isfooter={"N"} title={"Data Permintaan"} handleModal={tutup} />
            }
            <Card title={"Data Permintaan RM"} extra={extras()}>
                <TableComponent datas={data} onEdit={onLihat} />
            </Card>
        </div>
    )
}
