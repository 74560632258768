import React, { useEffect, useState } from 'react'
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";
import {
    SearchOutlined, DeleteOutlined, SettingOutlined, EditOutlined
    , PlusCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import TableComponent from './TableComponent.jsx'
import TableComponentModal from './TableComponentModal.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import AlertBox from "../../components/AlertBox";
import axios from "axios";
import "./styles.css";


export default function FormulaEdit() {
    const { Option } = Select;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [aletNote, setAlertNote] = useState(false)
    const [kodeFormula, setKodeFormula] = useState(null)
    const [namaFormula, setNamaFormula] = useState(null)
    const [keterangan, setKeterangan] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEdnDate] = useState(null)
    const [adukan, setAdukan] = useState(null)
    const [pilihan, setPilihan] = useState(null)
    const [isactive, setIsactive] = useState(null)
    const [pesanAlert, setPesanAlert] = useState(null)
    const [items, setItems] = useState([])
    const [selectedObj, setSelectedObj] = useState([])
    const [rowFix, setRowFix] = useState([])
    const { id } = useParams();
    const [objAdd, setObjAdd] = useState({})
    const [listObjAdd, setListObjAdd] = useState([])
    const [isFlag, setIsFlag] = useState(false)
    const [section, setSection] = ([{ value: 'MIXER', label: 'MIXER' },
    { value: 'EXTRUDER', label: 'EXTRUDER' },
    { value: 'PRINTING', label: 'PRINTING' },
    { value: 'LAMINATING', label: 'LAMINATING' },
    { value: 'PACKING', label: 'PACKING' }
    ])
    const [objOne, setObjOne] = useState(null)
    useEffect(() => {
        findOne()
        loadItem()
    }, [])
    const findOne = async () => {
        setLoading(true)
        await axios.post(`${API_URL}formula/findOne`, {
            formula_id: id
        })
            .then(function (response) {
                if (response.status == 200) {
                    // console.log(response.data);
                    setSelectedObj(response.data)
                    let objHeader = response.data[0]
                    // console.log(objHeader);
                    setIsactive(objHeader.isactive)
                    setKodeFormula(objHeader.kode_formula)
                    setNamaFormula(objHeader.nama_formula)
                    setStartDate(objHeader.start_date)
                    setEdnDate(objHeader.end_date)
                    setKeterangan(objHeader.keterangan)
                    // console.log(objHeader.keterangan, '*************8');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        setLoading(false)
    }
    const loadItem = async () => {
        await axios.get(`${API_URL}item/getItem`)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    setItems(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function reset() {
        setKodeFormula(null);
        setNamaFormula(null);
        setStartDate(null);
        setEdnDate(null); // Anda mungkin ingin memperbaiki ini ke "setEndDate"
        setAdukan(null);
        setItems([]);
        setSelectedObj([]);
        setRowFix([]);
    }
    const setQtyList = (qty, id, nama) => {

        let obj = selectedObj.find((o) => {
            return o.nama_item === nama
        })
        obj = { ...obj, qt_act: qty }
        // console.log(obj);
        let filter = []
        filter = rowFix.filter((o) => {
            return o.nama_item !== nama
        })
        filter.push(obj)
        console.log(filter);
        setRowFix(filter)
    }
    function hitung() {
        let total = 0
        total = selectedObj.reduce((total, item) => total + item.qty_adukan, 0);
        return total.toFixed(2)
    }
    return (
        <Card title="Edit Formula" color="#f0f2f5">
            <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
                <Alert message="Perubahan ini hanya mengubah komposisi formula" banner />
            </Space>
            <Spin spinning={loading}>
                <Row>
                    <Col span={"12"}>
                        <Row>
                            <Col span={12} style={{ paddingRight: 10 }}><div className="formDevide">
                                <label htmlFor="">Kode Formula</label>
                                <Input readonly placeholder="" value={kodeFormula} />
                            </div>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 10 }}><div className="formDevide">
                                <label htmlFor="">Jumlah Adukan</label>
                                <Input readonly placeholder="" value={hitung()} />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ paddingRight: 10 }}>
                                <div className="formDevide">
                                    <label htmlFor="">Nama Formula</label>
                                    <Input readonly placeholder="" value={namaFormula} />
                                </div>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 10 }}>
                                <label htmlFor="" style={{ color: 'red' }}>Aktif Formula</label>
                                <Select
                                    value={isactive == 1 ? 'YA' : 'TIDAK'}
                                    style={{ width: '100%' }}
                                    options={[{ value: '1', label: 'YA' },
                                    { value: '0', label: 'TIDAK' }
                                    ]}
                                    onChange={(v) => {
                                        setIsactive(v)
                                    }}
                                />
                            </Col>
                        </Row>

                        <div className="formDevide">
                            <label htmlFor="">Kategori Formula</label>
                            {/* <Input readonly placeholder="" value={keterangan} /> */}
                            <Select
                                value={selectedObj.length > 0 ? keterangan : ''}
                                style={{ width: '100%' }}
                                options={[{ value: 'MIXER', label: 'MIXER' },
                                { value: 'EXTRUDER', label: 'EXTRUDER' },
                                { value: 'PRINTING', label: 'PRINTING' },
                                { value: 'LAMINATING', label: 'LAMINATING' },
                                { value: 'PACKING', label: 'PACKING' }
                                ]}
                                onChange={(v) => {
                                    setKeterangan(v)
                                }}
                            />
                        </div>
                        {/* <Row>
                            <Col span={"12"} style={{ paddingRight: 10 }}>
                                <div className="formDevide">
                                    <label htmlFor="">Berlaku Dari</label>
                                    <Input type='date' placeholder="" value={startDate} onChange={(c) => {
                                        setStartDate(c.target.value)
                                    }} />
                                </div>
                            </Col>
                            <Col span={"12"} style={{ paddingLeft: 10 }}>
                                <div className="formDevide">
                                    <label htmlFor="">Berlaku Sampai</label>
                                    <Input type='date' placeholder="" value={endDate} onChange={(c) => {
                                        setEdnDate(c.target.value)
                                    }} />
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={12}>
                                <Button type='primary' primary onClick={async () => {
                                    let obj = {
                                        formula_id: id,
                                        edited: rowFix,
                                        added: listObjAdd,
                                        isactive:isactive
                                    }
                                    // console.log(rowFix);
                                    // return
                                    setLoading(true)
                                    await axios.post(`${API_URL}formula/editFormula`, obj)
                                        .then(function (response) {
                                            if (response.status == 200) {
                                                navigate("../../formula")
                                            }
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                    setLoading(false)
                                    // console.log(JSON.stringify(obj));

                                }}>Konfirmasi</Button>
                            </Col>
                            <Col span={12}>
                                <Button style={{ float: 'right' }} type='primary' danger onClick={() => {
                                    navigate("../../formula")
                                }}>Batalkan</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col span={24}>
                                <Card title="Tambah Komposisi Baru">
                                    <Row>
                                        <Col span={15} style={{ paddingRight: 6 }}>
                                            <div className="formDevide">
                                                <label htmlFor="">Pilih Item {(isFlag) && "(Sudah Ada !!!)"}</label>
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="Pilih Barang"
                                                    optionFilterProp="children"
                                                    value={pilihan}
                                                    onChange={(v) => {
                                                        let cari = items.find((o) => {
                                                            return o.m_item_id === v
                                                        })
                                                        let dtx = selectedObj.find((o) => {
                                                            return o.kode_item === cari.kode_item && o.qty_adukan > 0
                                                        })
                                                        if (dtx) {
                                                            setIsFlag(true)
                                                            return
                                                        }
                                                        setIsFlag(false)
                                                        setPilihan(v)
                                                        setObjAdd({
                                                            ...objAdd, m_item_id: cari.m_item_id,
                                                            kode_item: cari.kode_item,
                                                            nama_item: cari.nama_item
                                                        })
                                                    }}
                                                >
                                                    {items.map((item) => (
                                                        <Option key={item.m_item_id} value={item.m_item_id}>
                                                            {item.kode_item} - {item.nama_item}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col span={5}>
                                            <div className="formDevide">
                                                <label htmlFor="">Jumlah</label>
                                                <Input type='number' value={adukan} placeholder='' onChange={(v) => {
                                                    setAdukan(v.target.value)
                                                    setObjAdd({ ...objAdd, qty: v.target.value })
                                                }} />
                                            </div>
                                        </Col>
                                        <Col span={4} style={{ paddingLeft: 5, paddingTop: 20 }}>
                                            <Button danger onClick={() => {
                                                if (objAdd.qty > 0) {
                                                    let ox = listObjAdd
                                                    ox.push(objAdd)
                                                    console.log(ox);
                                                    setPilihan(null)
                                                    setAdukan(null)
                                                    // setListObjAdd(ox)
                                                } else {
                                                    return
                                                }
                                            }}><PlusCircleOutlined /> Tambah</Button>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingLeft: 10 }}>
                                        <Col span={4}>
                                            <b>Aksi</b>
                                        </Col>
                                        <Col span={16}><b>Item</b></Col>
                                        <Col span={4}><b>Qty</b></Col>
                                    </Row>
                                    {
                                        listObjAdd.map((o, index) => (
                                            <div key={index}>
                                                <Row style={{ paddingLeft: 10, paddingTop: 10 }}>
                                                    <Col span={4}>
                                                        <Button type='primary' danger onClick={() => {
                                                            let kode = o.kode_item
                                                            let newData = listObjAdd.filter(item => item.kode_item !== kode);
                                                            setListObjAdd(newData)
                                                        }}><CloseCircleOutlined /></Button>
                                                    </Col>
                                                    <Col span={16}>{o.nama_item}</Col>
                                                    <Col span={4}>{o.qty}</Col>
                                                </Row>
                                            </div>
                                        ))
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={"12"} style={{ paddingLeft: 20 }}>
                        <Row>
                            <Col span={24}>
                                {
                                    selectedObj.map((o, index) => (
                                        <Row key={index} style={{ marginBottom: 3, backgroundColor: '#f5f5f5', borderRadius: 10 }}>
                                            <Col span={15} style={{ padding: 5 }}>
                                                <div className="formDevide">
                                                    <label htmlFor="">Nama Item</label>
                                                    <Input readOnly placeholder="" value={o.nama_item} />
                                                </div>
                                            </Col>
                                            <Col span={4} style={{ padding: 5 }}>
                                                <div className="formDevide">
                                                    <label htmlFor="">Qty</label>
                                                    <Input placeholder="" value={o.qty_adukan} />
                                                </div>
                                            </Col>
                                            <Col span={5} style={{ padding: 5 }}>
                                                <div className="formDevide">
                                                    <label htmlFor="" color='#f0f2f5'>Adjust</label>
                                                    <Input placeholder="" onChange={(c) => {
                                                        // console.log(o);
                                                        // console.log(c.target.value, o.formula_detail_id, o.nama_item);
                                                        setQtyList(c.target.value, o.kode_item, o.nama_item)
                                                    }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </Card>
    )
}
