import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
  Card, Input, Spin, Row, Col, Select, Tabs
  , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import MixerTab from "./TabsComponent/MixerTab";
import ExtruderTab from "./TabsComponent/ExtruderTab";
import PACKINGTab from "./TabsComponent/PackaginTab";
import PrintingTab from "./TabsComponent/PrintingTab";
import LaminatingTab from "./TabsComponent/LaminatingTab";

import axios from "axios";
import {
  SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
  , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
} from '@ant-design/icons';
import "./styles.css";

export default function AddSPK() {
  const [tglSPK, setTglSPK] = useState(null)
  const [nomorSpk, setNomorSPK] = useState(null)
  const [dtlFormula, setDtlFormula] = useState(null)
  const [formula, setFormula] = useState([])
  const [hasilFormula, setHasilFormula] = useState([])
  const [hasilFormulaE, setHasilFormulaE] = useState([])
  const [hasilFormulaP, setHasilFormulaP] = useState([])
  const [hasilFormulaL, setHasilFormulaL] = useState([])
  const [hasilFormulaPK, setHasilFormulaPK] = useState([])
  const [customer, setCustomer] = useState(null)
  const [barang, setBarang] = useState(null)
  const [qty, setQty] = useState(null)
  const [qtyDus, setQtyDus] = useState(null)
  const [qtyPerDus, setQtyPerdus] = useState(null)
  const [berat, setBerat] = useState(null)
  const [dataCustomer, setDataCustomer] = useState([])
  const [dataItem, setDataItem] = useState([])
  const [partner, setPartner] = useState([])
  const [nomorPO, setNomorPO] = useState(null)
  const [tabsContent, setTabsContent] = useState([])
  const [note, setNote] = useState(null)
  const [warna, setWarna] = useState(null)
  const [tipe, setTipe] = useState(null)
  const [lebar, setLebar] = useState(null)
  const [tebal, setTebal] = useState(null)
  const [panjang, setPanjang] = useState(null)
  const [rumusProduksi, setRumusProduksi] = useState(null)
  const [objWIP, setObjWIP] = useState(null)
  const [objWIPmixer, setObjWIPmixer] = useState(null)
  const [objWIPextruder, setObjWIPextruder] = useState(null)
  const [objWIPprinting, setObjWIPprinting] = useState(null)
  const [objWIPlaminating, setObjWIPlaminating] = useState(null)
  const [objWIPPACKING, setObjWIPPACKING] = useState(null)
  const [lineMixer, setLineMixer] = useState(null)
  const [lineExt, setLineExt] = useState(null)
  const [linePrint, setLinePrint] = useState(null)
  const [lineLam, setLineLam] = useState(null)
  const [linePak, setLinePak] = useState(null)
  const [username, setUserName] = useState(null)

  const [formulaPkg, setFormulaPkg] = useState([])
  const [formulaExt, setFormulaExt] = useState([])

  const [mixer, setMixer] = useState(null)
  const [extruder, setExtruder] = useState(null)
  const [printing, setPrinting] = useState(null)
  const [laminating, setLaminating] = useState(null)
  const [listNat, setListNat] = useState(null)
  const [loading, setLoading] = useState(false)
  const { TextArea } = Input;
  const { Option } = Select;
  var wipExt = null
  var wipPrint = null
  var wipLam = null
  var wipPack = null
  useEffect(() => {
    let usr = JSON.parse(localStorage.getItem('user'))
    setUserName(usr.name)
    onLoad()
  }, [])
  const onLoad = () => {
    axios
      .get(`${API_URL}product/getProducts`, {})
      .then(function (response) {
        if (response.status == 200) {
          setDataItem(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${API_URL}partner/find`, {})
      .then(function (response) {
        if (response.status == 200) {
          let dt = response.data
          dt = dt.filter((o) => { return o.isVendor == 2 })
          setPartner(dt)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const generateMO = () => {
    console.log(barang);
    if (!barang || !qty || !berat) {
      setNote(`Lengkapi isian nama Barang, Qty dan Berat`)
      return
    }
    let p = panjang / 100
    let qt = qty
    let br = berat
    // console.log(br,qt,p);
    let adukan = (qty * p * br)
    setRumusProduksi(adukan)
    setDtlFormula([])
    setFormula([])
    setNote(null)
    axios
      .post(`${API_URL}spk/getProdukFormula`, {
        nama_barang: barang
      })
      .then(function (response) {
        if (response.status == 200) {
          let obx = response.data
          obx = obx.map(item => {
            return { ...item, qty: parseFloat(item.qty) * 1 }
          })

          // console.log(obx);
          // return
          setDtlFormula(obx)
          const uniqueData = Object.values(obx.reduce((acc, curr) => {
            if (!acc[curr.nama_formula] || acc[curr.nama_formula].sections < curr.sections) {
              acc[curr.nama_formula] = {
                nama_formula: curr.nama_formula
                , section: curr.sections
                , id_section: curr.m_section_id
                , kode_section: curr.kode_section
              };
            }
            return acc;
          }, {}));

          setFormula(uniqueData.sort((a, b) => a.id_section - b.id_section))
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const cekSebelumnya = (now) => {
    if (now == "PACKING") {
      if (lstFormula('LAMINATING').length == 0) {
        if (lstFormula('PRINTING').length == 0) {
          if (lstFormula('EXTRUDER').length == 0) {
            if (lstFormula('MIXER').length > 0) {
              return lstFormula('MIXER')
            } else {
              return []
            }
          } else {
            return lstFormula('EXTRUDER')
          }
        } else {
          return lstFormula('PRINTING')
        }
      } else {
        return lstFormula('LAMINATING')
      }
    } else if (now == "LAMINATING") {
      if (lstFormula('PRINTING').length == 0) {
        if (lstFormula('EXTRUDER').length == 0) {
          if (lstFormula('MIXER').length > 0) {
            return lstFormula('MIXER')
          } else {
            return []
          }
        } else {
          return lstFormula('EXTRUDER')
        }
      } else {
        return lstFormula('PRINTING')
      }
    } else if (now == "PRINTING") {
      if (lstFormula('EXTRUDER').length == 0) {
        if (lstFormula('MIXER').length > 0) {
          return lstFormula('MIXER')
        } else {
          return []
        }
      } else {
        return lstFormula('EXTRUDER')
      }
    } else if (now == "EXTRUDER") {
      if (lstFormula('MIXER').length > 0) {
        return lstFormula('MIXER')
      } else {
        return []
      }
    }
  }
  const lstFormula = (v) => {
    let lstx = dtlFormula.filter((o) => {
      return o.sections === v && o.qty > 0
    })
    // console.log(lstx);
    return lstx
  }
  const rumusWIPM = (ls, v) => {
    ls = ls.filter((o) => { return o.sections == 'MIXER' })
    const totalStokAkhir = ls.reduce((total, item) => total + item.qty, 0);
    let lots = Math.ceil(v / totalStokAkhir)
    return totalStokAkhir * lots
  }
  const tabSwitch = (obj) => {
    let list = lstFormula(obj.section)
    // console.log(list);
    // return
    if (obj.section == "MIXER") {
      return <MixerTab keterangan={"MIXER"}
        formulas={list}
        jmladukan={rumusProduksi}
        line={setLineMixer}
        masterFormula={dtlFormula}
        reSetFormula={setHasilFormula}
      />
    } else if (obj.section == "EXTRUDER") {
      console.log(obj, '**** comeon', dtlFormula);
      // return
      let px = cekSebelumnya('EXTRUDER')
      let add = px.length > 0 ?
        { nama_produk: `WIP${px[0].kode_section}.${px[0].kode_formula}`, satuan: `Kg`, qty: (rumusProduksi) }
        : null
      wipExt = add
      list = list.filter(item => item.qty > 0);
      list = list.map((o) => {
        let qtyp = o.qty
        return o = { ...o, qty_std: qtyp, qty: qtyp * (parseFloat(panjang / 100) * parseFloat(qty)) }
      })
      // console.log('extruder ----', list);
      // return null
      return <ExtruderTab keterangan={"EXTRUDER"} formulas={list} additional={add}
        panjang={panjang}
        qty_produksi={qty}
        line={setLineExt}
        listNat={setListNat}
        masterFormula={dtlFormula}
        resetFormula={setFormulaExt}
        barang={barang} berat={berat}
      />
    } else if (obj.section == "PRINTING") {
      let px = cekSebelumnya('PRINTING')
      let cekWip = extruder == 1 ? `WIP${px[0].kode_section}.` : ''
      let add = px.length > 0 ?
        // { nama_produk: `${cekWip}${barang}`, satuan: `Lbr`, qty: qty }
        { nama_produk: `${cekWip}${barang}`, satuan: `Lbr`, qty: (parseFloat(panjang / 100) * 1 * parseFloat(qty)) }
        : null
      wipPrint = add
      return <PrintingTab keterangan={"PRINTING"} formulas={list} additional={add}
        line={setLinePrint} masterFormula={dtlFormula}
        resetFormula={setHasilFormula} panjang={panjang} qty_produksi={qty} berat={berat} />
      // return null
    } else if (obj.section == "LAMINATING") {
      let px = cekSebelumnya('LAMINATING')
      let add = px.length > 0 ?
        { nama_produk: `${px[0].kode_section}-${px[0].nama_formula}`, satuan: `Lbr`, qty: qty }
        : null
      wipLam = add
      console.log(list, add, dtlFormula);
      // return
      return <LaminatingTab keterangan={"LAMINATING"} formulas={list} additional={add}
        line={setLineLam}
        masterFormula={dtlFormula}
        resetFormula={setHasilFormula} />
    } else if (obj.section == "PACKING") {
      let px = cekSebelumnya('PACKING')
      let cekWip = ``
      if (!laminating) {
        cekWip = laminating == 1 ? `WIP${px[0].kode_section}.` : ''
      } else {
        if (!printing) {
          cekWip = printing == 1 ? `WIP${px[0].kode_section}.` : ''
        } else {
          cekWip = extruder == 1 ? `WIP${px[0].kode_section}.` : ''
        }
      }

      let add = px.length > 0 ?
        { nama_produk: `${cekWip}${barang}`, satuan: `Lbr`, qty: (parseFloat(panjang / 100) * 1 * parseFloat(qty)) }
        : null
      wipPack = add
      list = list.map((o) => {
        let qtyp = o.qty
        return o = { ...o, qty_std: qtyp, qty: qtyp * (parseFloat(panjang / 100) * 1 * parseFloat(qty)) }
      })
      return <PACKINGTab keterangan={"PACKING"} formulas={list} additional={add}
        resetFormula={setFormulaPkg} panjang={panjang} qty_produksi={qty}
        line={setLinePak}
        masterFormula={dtlFormula}
      />
      // return null
    }
  }
  const konfirmMO = () => {
    let detailFormula = []
    //mixer
    let mix = dtlFormula.filter((o) => { return o.sections == "MIXER" })
    const totalStokAkhir = mix.reduce((total, item) => total + item.qty, 0);
    let lots = Math.ceil(rumusProduksi / totalStokAkhir)
    mix = mix.map((o) => {
      let op = { ...o, qty: o.qty * lots }
      detailFormula.push(op)
      return op
    })

    //extruder
    let ext = formulaExt.filter((o) => { return o.sections == "EXTRUDER" })
    if (ext.length > 0) {
      ext.map((o) => {
        detailFormula.push(o)
      })
      let WIP = { ...ext[0], kode_item: wipExt.nama_produk, nama_item: wipExt.nama_produk, satuan: 'KG', qty: wipExt.qty }
      detailFormula.push(WIP)
    }

    //printing
    let print = dtlFormula.filter((o) => { return o.sections == "PRINTING" })
    if (print.length > 0) {
      print = print.map((o) => {
        let qtyp = o.qty
        o = { ...o, qty: qtyp * parseFloat(panjang / 100) * parseFloat(qty) }
        detailFormula.push(o)
        return o
      })
      let WIP = { ...print[0], kode_item: wipPrint.nama_produk, nama_item: wipPrint.nama_produk, satuan: wipPrint.satuan, qty: wipPrint.qty }
      detailFormula.push(WIP)
    }
    // //packing
    let packing = formulaPkg.filter((o) => { return o.sections == "PACKING" })
    if (packing.length > 0) {
      packing.map((o) => {
        detailFormula.push(o)
      })
      if (wipPack) {
        let WIP = { ...packing[0], kode_item: wipPack.nama_produk, nama_item: wipPack.nama_produk, satuan: wipPack.satuan, qty: wipPack.qty }
        detailFormula.push(WIP)
      }
    }

    let laminating = formulaPkg.filter((o) => { return o.sections == "LAMINATING" })
    if (laminating.length > 0) {
      laminating.map((o) => {
        detailFormula.push(o)
      })
    }
    // console.log(detailFormula);
    // return
    let objek = {
      tglSPK: tglSPK,
      barang: barang,
      qty: qty,
      tebal: tebal,
      berat: berat,
      panjang: panjang,
      qtyDus: qtyDus,
      nomorPO: nomorPO,
      warna: warna,
      partner: customer,
      lebar: lebar,
      lineMixer: lineMixer,
      lineExt: lineExt,
      linePrint: linePrint,
      lineLam: lineLam,
      linePak: linePak,
      listNat: listNat,
      dtlFormula: detailFormula,
      username: username
    }

    setLoading(true)
    axios
      .post(`${API_URL}spk/createSPK`, objek)
      .then(function (response) {
        setLoading(false)
        if (response.status == 200) {
          console.log(response.data);
          if (response.data.kode == 500) {
            alert(response.data.msg)
          } else {
            alert(response.data.msg)
            window.history.back();
          }

        }
      })
      .catch(function (error) {
        setLoading(false)
      });
  }
  return (
    <Card title={"Tambah SPK"}>
      <Spin spinning={loading}>
        <Row>
          <Col span={10}>
            <Row>
              <Col span={12}>
                <div className="formDevide">
                  <label htmlFor="">Tgl SPK</label>
                  <Input type='date' value={tglSPK}
                    onChange={(e) => {
                      setTglSPK(e.target.value)
                    }} />
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: 5 }}>
                <div className="formDevide">
                  <label htmlFor="">Nomor SO</label>
                  <Input type='text' value={nomorPO}
                    onChange={(e) => {
                      setNomorPO(e.target.value)
                    }} />
                </div>
              </Col>
            </Row>

            <div className="formDevide">
              <label htmlFor="">Customer</label>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih Customer"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(v) => {
                  setCustomer(v)
                }}
              >
                {partner.map(item => (
                  <Option key={item.m_partner_id} value={item.m_partner_id}>
                    {item.nama_partner}
                  </Option>
                ))}
              </Select>
            </div>
            <Row>
              <Col span={12} style={{ paddingLeft: 0 }}>
                <div className="formDevide">
                  <label htmlFor="">Nama Barang</label>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Pilih Barang"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(v) => {
                      setBarang(v)
                      let obj = dataItem.find((o) => {
                        return o.nama_barang == v
                      })
                      setTipe(obj.jenis_barang)
                      setPanjang(obj.panjang)
                      setTebal(obj.tebal)
                      setPanjang(obj.panjang)
                      setLebar(obj.lebar)
                      setMixer(obj.is_wip_mixer)
                      setExtruder(obj.is_wip_extruder)
                      setPrinting(obj.is_wip_printing)
                      setLaminating(obj.is_wip_lamiating)
                      console.log(obj);
                    }}
                  >
                    {dataItem.map(item => (
                      <Option key={item.nama_barang} value={item.nama_barang}>
                        {item.nama_barang}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: 5 }}>
                <div className="formDevide">
                  <label htmlFor="">Tipe</label>
                  <Input type='text' value={tipe} style={{ backgroundColor: '#e9ecef' }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <div className="formDevide">
                      <label htmlFor="">Panjang (cm)</label>
                      <Input type='text' value={panjang} style={{ backgroundColor: '#e9ecef' }} />
                    </div>
                  </Col>
                  <Col span={12} style={{ paddingLeft: 8 }}>
                    <div className="formDevide">
                      <label htmlFor="">Lebar (cm)</label>
                      <Input type='text' value={lebar} style={{ backgroundColor: '#e9ecef' }} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ paddingLeft: 5 }}>
                <div className="formDevide">
                  <label htmlFor="">Tabal (mm)</label>
                  <Input type='number' value={tebal} style={{ backgroundColor: '#e9ecef' }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingLeft: 0 }}>
                <div className="formDevide">
                  <label htmlFor="">Qty Produksi</label>
                  <Input type='number' value={qty}
                    onChange={(e) => {
                      setQty(e.target.value)
                    }} />
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: 5 }}>
                <div className="formDevide">
                  <label htmlFor="">Berat/ Barang</label>
                  <Input type='number' value={berat}
                    onChange={(e) => {
                      setBerat(e.target.value)
                    }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: 5 }}>
                <div className="formDevide">
                  <label htmlFor="">Warna Mixing</label>
                  <Input type='text' value={warna}
                    onChange={(e) => {
                      setWarna(e.target.value)
                    }} />
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: 0 }}>
                <div className="formDevide">
                  <label htmlFor="">Qty Dus</label>
                  <Input type='number' value={qtyDus}
                    onChange={(e) => {
                      setQtyDus(e.target.value)
                    }} />
                </div>
              </Col>
            </Row>
            {(note) && <p style={{ color: 'red', paddingBottom: 10 }}>{note}</p>}
            <Row>
              <Col span={12}>
                <Button style={{ float: "left" }}
                  onClick={generateMO}
                  type="primary" primary>
                  <SettingOutlined />Generate SPK
                </Button>
              </Col>
              <Col span={12}>
                <Button style={{ float: "right", backgroundColor: '#dd5e3f' }}
                  onClick={konfirmMO}
                  type="primary" primary>
                  <CheckOutlined /> Konfirmasi
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={14} style={{ paddingLeft: 20 }}>
            {
              (formula.length > 0) ?
                <Tabs type="card">
                  {
                    formula.map((item, index) => (
                      <Tabs.TabPane tab={item.section} key={index}>
                        <div style={{ paddingLeft: 10 }}>
                          {tabSwitch(item)}
                        </div>
                      </Tabs.TabPane>
                    ))
                  }
                </Tabs>
                : null
            }
          </Col>
        </Row>
      </Spin>
    </Card>
  )
}
