import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import TableComponent from './TableComponent.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined
} from '@ant-design/icons';
import "./styles.css";



export default function SpkForm() {
    const [tgl2, setTgl2] = useState(null)
    const [tgl1, setTgl1] = useState(null)
    const [status, setStatus] = useState(null)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    useEffect(() => {
        onLoad()
    }, [])
    const onLoad = async () => {
        setLoading(true)
        await axios.get(`${API_URL}spk/find`)
            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setData(response.data)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });
    }
    const onDelete = async (c) => {
        const result = window.confirm('Yakin menghapus SPK ini ...???')
        if (!result) {
            return
        }
        setLoading(true)
        await axios.post(`${API_URL}spk/deleteSPK`, {
            spk_id: c.spk_id
        })
            .then(function (response) {
                console.log(response.data);
                setLoading(false)
                if (response.status == 200) {
                    if (response.data.kode == 500) {
                        alert(response.data.msg)
                    } else {
                        alert(response.data.msg)
                        onLoad()
                    }
                }
            })
            .catch(function (error) {
                alert(error.message)
                setLoading(false)
                console.log(error.message);
            });
    }
    const onDetail = (v) => {
        console.log(v);
        navigate(`./detailspk/${v.spk_id}`)
    }
    const formFilter = () => {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <Input value={tgl1} style={{ marginRight: '10px' }} type='date' placeholder="From" onChange={(e) => { setTgl1(e.target.value) }} />
                    <Input value={tgl2} style={{ marginRight: '10px' }} type='date' placeholder="To" onChange={(e) => { setTgl2(e.target.value) }} />
                    <Button type='primary' onClick={cariData} primary><SearchOutlined /></Button>
                    <Button type='primary' style={{ marginLeft: '10px', backgroundColor: '#23aa59' }} onClick={() => {
                        navigate("/addspk")
                    }} primary><PlusCircleOutlined /></Button>
                </div>
            </div>
        )
    }
    const formHeader = () => {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    Data SPK
                </div>
            </div>
        )
    }
    const cariData = async () => {
        setLoading(true)
        await axios.get(`${API_URL}spk/find?tgl1=${tgl1}&tgl2=${tgl2}`)
            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setData(response.data)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });
    }
    return (
        <Card title={formHeader()}
            extra={formFilter()}
        >
            <Spin spinning={loading}>
                <TableComponent datas={data} onEdit={onDetail} onDelete={onDelete} />
            </Spin>
        </Card>
    )
}
