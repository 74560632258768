import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";
import TableComponent from './TableComponent.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
    , ExportOutlined
} from '@ant-design/icons';

export default function SalesOut() {
    const { Option } = Select;

    const [data, setData] = useState([])
    const [objPo, setObjPo] = useState([])
    const [cust, setCust] = useState([])
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState(null)

    const [pilihanCust, setPilihanCust] = useState(null)
    const [pilihanSO, setPilihanSO] = useState(null)
    const [nopo, setNopo] = useState(null)
    useEffect(() => {
        onLoad()
        partner()
    }, [])
    const partner = async () => {
        await axios.get(`${API_URL}partner/find`)
            .then(function (response) {
                setCust(response.data)
            })
    }
    const onLoad = async () => {
        // console.log(localStorage.getItem('user'), 'user ******88');

        await axios.post(`${API_URL}transaksimasuk/findSalesOrder`, {
            cust: pilihanCust ? pilihanCust : null,
            nomorso: pilihanSO ? pilihanSO : null,
        })
            .then(function (response) {
                setData(response.data)
            })
    }
    const cariPO = async () => {
        try {
            await axios.post(`${API_URL}transaksimasuk/findPO`, {
                nomor_po: nopo
            })
                .then(function (response) {
                    if (response.data.length == 0) {
                        alert(`Tidak ditemukan`)
                        return
                    }
                    let obx = response.data
                    let px = obx.map((o, index) => {
                        o = { ...o, idx: index }
                        return o
                    })
                    console.log(px);
                    setObjPo(px.sort(function (a, b) {
                        return a.idx - b.idx;
                    }))
                })
        } catch (error) {
            if (error.response) {
                //   setMsg(error.response.data.msg);
            }
        }
    }
    const formPO = () => {
        return (
            <>
                <Row>
                    <Col span={22}><Input placeholder="Ketik Nomor PO" onChange={(c) => { setNopo(c.target.value) }} /></Col>
                    <Col span={2}><Button type="primary" onClick={cariPO}>Cari</Button></Col>
                </Row>
                {
                    (objPo.length > 0)
                    &&
                    <>
                        <Row style={{ marginTop: 10 }}>
                            <Col>
                                <label>Nama Customer</label>
                                <p style={{ fontSize: 16 }}><b>{objPo[0].nama_partner}</b></p>
                            </Col>
                        </Row>
                        <Row style={{ backgroundColor: '#9b9c9f', padding: 8, marginBottom: 1, marginTop: 10 }}>
                            <Col span={4}>Nama Product</Col>
                            <Col span={3}>Tipe</Col>
                            <Col span={3}>Warna</Col>
                            <Col span={2}>P/L</Col>
                            <Col span={4}>Produksi</Col>
                            <Col span={3}>Diambil</Col>
                            <Col span={2}>Sisa</Col>
                            <Col span={2}>Ambil/Lbr</Col>
                        </Row>
                        {
                            objPo.map((o, index) => (
                                <Row key={index} style={{ backgroundColor: index % 2 ? '#ffff' : '#fafafa', padding: 8, marginBottom: 1 }}>
                                    <Col span={4}>{o.nama_product}</Col>
                                    <Col span={3}>{o.jenis_barang}</Col>
                                    <Col span={3}>{o.warna_mixing !== 'null' && o.warna_mixing}</Col>
                                    <Col span={2}>{o.panjang}/{o.lebar}</Col>
                                    <Col span={4}>{o.qty_produksi}</Col>
                                    <Col span={4}>{o.qty_keluar}</Col>
                                    <Col span={2}>{o.qty_produksi - (o.qty_keluar ? o.qty_keluar : 0)}</Col>
                                    <Col span={2}>
                                        <Input type="numeric" readOnly={o.qty_produksi == o.qty_keluar ? true : false} onChange={(e) => {
                                            let pn = objPo.filter((v) => {
                                                return v.nama_product !== o.nama_product
                                            })

                                            let dt = { ...o, qty_keluar: o.qty_keluar, qty_so: e.target.value }
                                            console.log(dt);
                                            pn.push(dt)
                                            setObjPo(pn.sort(function (a, b) {
                                                return a.idx - b.idx;
                                            }))
                                        }} />
                                    </Col>
                                </Row>
                            ))
                        }
                        <Row>
                            <Col span={24}>
                                <Button style={{ float: 'right', marginTop: 10 }} type="primary" onClick={async () => {
                                    console.log(objPo);
                                    await axios.post(`${API_URL}transaksimasuk/transksiKeluarFG`, {
                                        objek: objPo
                                    })
                                        .then(function (response) {
                                            if (response.status == 200) {
                                                alert(response.data.msg)
                                                setObjPo([])
                                                setModal(false)
                                                onLoad()
                                            }
                                        })
                                }}>Konfirmasi</Button>
                            </Col>
                        </Row>

                    </>
                }
            </>
        )
    }
    const extrasFilter = () => {
        return (
            <Row style={{ width: 1000, paddingBottom: 10 }}>
                <Col span={24}>
                    <Row>
                        <Col span={4} style={{ paddingRight: 7 }}>
                            {/* <p>Tgl Produksi</p>
                            <Input type="date" onChange={(v) => {
                                setFilter({ ...filter, tgl1: v.target.value })
                            }} /> */}
                        </Col>
                        <Col span={4} style={{ paddingRight: 7 }}>
                            {/* <p>Tgl Produksi</p>
                            <Input type="date" onChange={(v) => {
                                setFilter({ ...filter, tgl2: v.target.value })
                            }} /> */}
                        </Col>
                        <Col span={7} style={{ paddingRight: 7 }}>
                            <p>Customer</p>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Pilih Customer"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(v) => {
                                    setPilihanCust(v)
                                }}
                            >
                                {cust.map(item => (
                                    <Option key={item.nama_partner} value={item.nama_partner}>
                                        {item.nama_partner}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4} style={{ paddingRight: 7 }}>
                            <p>Nomor SO</p>
                            <Input type="text" onChange={(v) => {
                                setPilihanSO(v.target.value)
                            }} />
                        </Col>
                        <Col span={2} style={{ float: 'right', paddingTop: 19 }}>
                            <Button onClick={onLoad} style={{ float: 'right' }} type="primary" primary><SearchOutlined /></Button>
                        </Col>
                        <Col span={2} style={{ float: 'right', paddingTop: 19 }}>
                            <Button onClick={() => setModal(true)} style={{ float: 'right' }} type="primary" danger><PlusCircleOutlined /></Button>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={9}>
                    <Row>
                        <Col span={16}>
                            <Input placeholder="Cari Nomor SPK" />
                        </Col>
                        <Col span={4}>
                            <Button style={{ float: 'right' }} type="primary" danger><ExportOutlined /></Button>
                        </Col>
                        <Col span={4}>
                            <Button onClick={onLoad} style={{ float: 'right' }} type="primary" primary><SearchOutlined /></Button>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        )
    }
    return (
        <div>
            {
                (modal) &&
                <ModalForm title={"Cari Nomor SO"} handleModal={() => {
                    setModal(false)
                    setObjPo([])
                }} content={formPO()} isfooter={'N'} width={1050} />
            }
            <Card title={"Data Sales Order (Pengambilan Barang)"} extra={extrasFilter()}>
                <TableComponent datas={data} />
            </Card>
        </div>
    )
}
