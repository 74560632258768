import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import TableComponent from './TableComponent.jsx'
import TableDetail from './TableDetail'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";
import {
  SearchOutlined, DeleteOutlined, SettingOutlined, EditOutlined
  , PlusCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';

export default function BarangMasuk() {
  const { Option } = Select;
  const [data, setData] = useState([])
  const [dataOne, setDataOne] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalLihat, setModalLihat] = useState(false)
  const [partner, setPartner] = useState([])
  const [items, setItems] = useState([])
  const [nomorPo, setNomorPO] = useState(null)
  const [nomorSJ, setNomorSJ] = useState(null)
  const [created, setCreated] = useState(null)
  const [pilihanPartner, setPilihanPartner] = useState(null)
  const [pilihanItem, setPilihanItem] = useState(null)
  const [objItem, setObjItem] = useState(null)
  const [useId, setuserId] = useState(null)
  const [listObj, setListObj] = useState([])

  useEffect(() => {
    let objUsr =  localStorage.getItem('user')
    objUsr = JSON.parse(objUsr)
    // objUsr = objUsr.dataUser
    // console.log(objUsr);
    setuserId(objUsr.uuid)
    onLoad()
  }, [])
  const onLoad = () => {
    axios
      .get(`${API_URL}transaksimasuk/find`, {})
      .then(function (response) {
        if (response.status == 200) {
          setData(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${API_URL}item/getItem`, {})
      .then(function (response) {
        if (response.status == 200) {
          setItems(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${API_URL}partner/find`, {})
      .then(function (response) {
        if (response.status == 200) {
          setPartner(response.data.filter((o)=>{
            return o.isVendor == 1
          }))
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const clear = () =>{
    setCreated(null)
    setNomorPO(null)
    setNomorSJ(null)
    setCreated(null)
    setPilihanPartner(null)
    setPilihanItem(null)
    setObjItem(null)
    setListObj([])
  }
  const handleModal = () => {
    setModalAdd(!modalAdd)
  }

  const findOne = (obj) =>{
    axios
      .get(`${API_URL}transaksimasuk/findOne?transaksi_masuk_id=${obj.transaksi_masuk_id}`, {})
      .then(function (response) {
        if (response.status == 200) {
          // console.log(response.data);
          setDataOne(response.data)
          setModalLihat(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const v_modal = () => {
    return (
      <>
        <Row>
          <Col span={"12"} style={{ padding: 10 }}>
            <div className="formDevide">
              <label htmlFor="">Pilih Supplier</label>
              <Select
                defaultValue={pilihanPartner ? pilihanPartner : 'Pilih'} 
                style={{
                  width: '100%',
                }}
                onChange={(value) => {
                  console.log(value);
                  setPilihanPartner(value);
                }}
              >
                {partner.map((option) => (
                  <Option key={option.m_partner_id} value={option.m_partner_id}>
                    {option.nama_partner}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="formDevide">
              <label htmlFor="">Nomor Po</label><br />
              <Input value={nomorPo} onChange={(c) => {
                setNomorPO(c.target.value)
              }} />
            </div>
            <div className="formDevide">
              <label htmlFor="">Nomor SJ</label><br />
              <Input value={nomorSJ} onChange={(c) => {
                setNomorSJ(c.target.value)
              }} />
            </div>
          </Col>
          <Col span={"12"} style={{ padding: 10 }}>
            <div className="formDevide">
              <label htmlFor="">Pilih Barang</label>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih Item"
                value={pilihanItem}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  // console.log(value);
                  setPilihanItem(value);
                  let obx = items.find((o) => {
                    return o.m_item_id === value
                  })
                  console.log(obx);
                  setObjItem(obx)
                }}
              >
                {items.map((option) => (
                  <Option key={option.m_item_id} value={option.m_item_id}>
                    {option.nama_item}
                  </Option>
                ))}
              </Select>
              {/* <Row>
                <Col>
                <b style={{fontSize:14}}>{objItem ? objItem.nama_item : "-"}</b>
                </Col>
              </Row> */}
              <Row style={{ marginTop: 7 }}>
                <Col span={8} style={{ padding: 5 }}>
                  <label style={{ fontSize: 14 }}>Satuan</label>
                  <Input readOnly value={objItem ? objItem.uom : ""} />
                </Col>
                <Col span={8} style={{ padding: 5 }}>
                  <label style={{ fontSize: 14 }}>Harga/Pcs</label>
                  <Input value={objItem ? objItem.price : ""} onChange={(c) => {
                    setObjItem({ ...objItem, price: c.target.value })
                  }}/>
                </Col>
                <Col span={8} style={{ padding: 5 }}>
                  <label style={{ fontSize: 14 }}>QTY</label>
                  <Input value={objItem ? objItem.qty : ''} onChange={(c) => {
                    setObjItem({ ...objItem, qty: c.target.value })
                  }} />
                </Col>
              </Row>
              <Row>
                <Col span={12}><Button type="primary" primary style={{ float: "left" }}
                  onClick={ async () => {
                    // console.log(listObj);
                    if(!pilihanPartner || !nomorPo || !nomorSJ){
                      alert('Lengkapi dulu')
                    }

                    let obj = {
                      m_partner_id : pilihanPartner,
                      nomor_po : nomorPo,
                      nomor_sj : nomorSJ,
                      m_user_id : useId,
                      detail : listObj
                    }
                    console.log(obj);
                    setLoading(true)
                    await axios.post(`${API_URL}transaksimasuk/create`, obj)
                        .then(function (response) {
                            if (response.status == 200) {
                              clear()
                              handleModal()
                              onLoad()
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    setLoading(false)

                  }}>Konfirmasi</Button></Col>
                <Col span={12}><Button type="primary" style={{ float: "right", backgroundColor: '#6bcd39' }}
                  onClick={() => {
                    console.log(objItem);
                    if(!objItem){
                      return
                    }
                    setListObj([...listObj, objItem])
                    setObjItem(null)
                  }}>Tambah Data</Button></Col>
              </Row>
              {
                listObj.map((o,index) => (
                  <Row key={index} style={{ marginTop: 8, backgroundColor: "#f4efef", paddingTop: 10 }}>
                    <Col span={4} style={{ textAlign: 'center' }}>
                      <Button type='primary' danger onClick={() => {
                        let ls = listObj.filter((v)=>{
                          return v.m_item_id !== o.m_item_id
                        })
                        setListObj(ls)
                      }}><CloseCircleOutlined /></Button>
                    </Col>
                    <Col span={20}>
                      <b>{o.nama_item}</b>
                      <p>{o.qty} {o.uom}</p>
                    </Col>
                  </Row>
                ))
              }
            </div>
          </Col>
        </Row>
      </>
    )
  }
  const v_modal_edit = () =>{
    return (
      <>
        <Row>
          <Col span={8} style={{padding:5}}>
            <b>Suplier</b>
            <p>{dataOne ? dataOne[0].nama_partner : ""}</p>
          </Col>
          <Col span={8} style={{padding:5}}>
            <b>Nomor PO</b>
            <p>{dataOne ? dataOne[0].nomor_po : ""}</p>
          </Col>
          <Col span={8} style={{padding:5}}>
            <b>Nomor SJ</b>
            <p>{dataOne ? dataOne[0].nomor_sj : ""}</p>
          </Col>
        </Row>
        <Row style={{marginTop:20}}>
          <Col span={24}>
            <TableDetail datas={dataOne}/>
          </Col>
        </Row>
      </>
    )
  }
  const handleModal2 = () =>{
    setModalLihat(!modalLihat)
    setDataOne([])
  }
  return (
    <>
      {
        (modalAdd) &&
        <ModalForm
          title={"Transaksi Masuk"}
          handleModal={handleModal}
          isfooter={"N"} width={1000} content={v_modal()} />
      }
      {
        (modalLihat) &&
        <ModalForm title={"Penerimaan Barang"} content={v_modal_edit()}
        handleModal={handleModal2}
        isfooter={'N'}
        width={700}/>
      }
      <Card title="Data Barang Masuk">
        <Button type="primary" primary style={{ marginBottom: 10 }} onClick={() => {
          handleModal()
        }}>Add Transaksi</Button>
        <TableComponent datas={data} onEdit={findOne}/>
      </Card>
    </>
  )
}
