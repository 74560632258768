import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";


export default function MixerTab({ setting, wip, keterangan, formulas, id, setId }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [objFormula, setObjFormlua] = useState(null)

  useEffect(() => {
    // console.log(formulas,'---->',keterangan);

    let dts = formulas.filter((o) => {
      return o.keterangan == 'MIXER'
    })
    // console.log(`****`,dts);
    setDataFormula(dts)
    if (id) {
      let op = formulas.find((o) => {
        return o.formula_id === id
      })

      setId(id)
      findOne(id)
      setObjFormlua(op);
    }
  }, [])
  const findOne = async (v) => {
    await axios.post(`${API_URL}formula/findOne`, {
      formula_id: v
    })
      .then(function (response) {
        if (response.status == 200) {
          // console.log(`dtl formula`,response.data);
          setDtlFormula(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div>
      <Row>
        <Col span={12} style={{padding:10}}>
          <label htmlFor="">Pilih Formula</label>
          <Select
            style={{
              width: '100%',
            }}
            value={id}
            onChange={(value) => {
              console.log(value);
              let op = dataFormula.find((o) => {
                return o.formula_id === value
              })
              setId(value)
              findOne(value)
              setObjFormlua(op);
            }}
          >
            {dataFormula.map((option) => (
              <Option key={option.formula_id} value={option.formula_id}>
                {option.nama_formula}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={12} style={{padding:10}}>
            <label>Output Section</label>
            <Select 
            value={wip == 1 ? 'WIP' : 'FG'}
            style={{
              width: '100%',
            }}
            options={[{ label: 'WIP', value: '1' }, { label: 'FG', value: '0' }]} onChange={(v) => {
              setting(v)
            }}/>
        </Col>
      </Row>
      <>
        {
          (objFormula) &&
          <>
            <Row style={{ marginTop: 20, paddingLeft: 7, backgroundColor: '#ededed', borderRadius: 5 }}>
              <Col span={8}>
                <div className="formDevide">
                  <label htmlFor="" style={{ fontSize: 10 }}><b>Kode Formula</b></label><br />
                  <label htmlFor="">{objFormula.kode_formula}</label>
                </div>
              </Col>
              <Col span={8}>
                <div className="formDevide">
                  <label htmlFor="" style={{ fontSize: 10 }}><b>Nama Formula</b></label><br />
                  <label htmlFor="">{objFormula.nama_formula}</label>
                </div>
              </Col>
              <Col span={8}>
                <div className="formDevide">
                  <label htmlFor="" style={{ fontSize: 10 }}><b>Total Adukan</b></label><br />
                  <label htmlFor="">{objFormula.qty_adukan}</label>
                </div>
              </Col>
            </Row>
            <TableComponentModal datas={dtlFormula} />
          </>
        }
      </>
    </div>
  )
}
