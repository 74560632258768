import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
    , ExportOutlined
} from '@ant-design/icons';
import "./styles.css";
import SelectBox from "../../components/SelectBox.jsx";
import TblSalesOrder from "./Table/TblSalesOrder.jsx";

export default function SalesOrder() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState(null)
    const [tgl1, setTgl1] = useState(null)
    const [tgl2, setTgl2] = useState(null)
    const [key, setKey] = useState(null)
    const [dataUser, setDataUser] = useState(null)
    const filter1 = [{ label: 'Waiting', value: 'Waiting' }
        , { label: 'On Progress', value: 'On Progress' }
        , { label: 'Complete', value: 'Complete' }]

    useEffect(() => {
        let dtusr = localStorage.getItem('user')
        dtusr = JSON.parse(dtusr)
        console.log(dtusr);
        setDataUser(dtusr)
    }, [])
    useEffect(() => {
        onLoad()
    }, [dataUser])
    const onLoad = async () => {
        if (dataUser.role == 4) {
            setFilter({ ...filter, customer_id: dataUser.uuid })
            console.log('wooke', dataUser.uuid);
        } else {
            setFilter({ ...filter, customer_id: null })
        }
        // console.log(key);
        // return
        await axios.post(`${API_URL}report/salesOrder`, {
            status: dataUser ? dataUser.status : null,
            customer_id: dataUser ? dataUser.uuid : null,
            tgl1: filter ? filter.tgl1 : null,
            tgl2: filter ? filter.tgl2 : null,
            keys: key

        })
            .then(function (res) {
                if (res.status == 200) {
                    console.log(res.data);
                    setData(res.data)
                }
            })
    }
    const extrasFilter = () => {
        return (
            <Row style={{ width: '550px' }}>
                {/* <Col span={7} style={{ paddingRight: 10 }}>
                    <Input value={tgl1} type="date" onChange={(c) => {
                        setTgl1(c.target.value)
                        setFilter({ ...filter, tgl1: c.target.value })
                    }} />
                </Col>
                <Col span={7} style={{ paddingRight: 10 }}>
                    <Input value={tgl2} type="date" onChange={(c) => {
                        setTgl2(c.target.value)
                        setFilter({ ...filter, tgl2: c.target.value })
                    }} />
                </Col> */}
                <Col span={21}>
                    <Input placeholder="Cari Nomor SO / Product / SPK" value={key} onChange={(c) => {
                        setKey(c.target.value)
                    }} />
                </Col>
                <Col span={3}>
                    <Button onClick={onLoad} style={{ float: 'right' }} type="primary" primary><SearchOutlined /></Button>
                </Col>
            </Row>
        )
    }
    return (
        <div>
            <Card title={"Sales Order"} extra={extrasFilter()}>
                <TblSalesOrder datas={data} />
            </Card>
        </div>
    )
}
