import React,{useEffect} from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import Login from "../components/Login";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError,user } = useSelector((state) => state.auth);

  // useEffect(()=>{
  //   dispatch(getMe());
  // },[dispatch])
  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);
  return (
    <React.Fragment>
        <Navbar />
        <div className="columns mt-0" style={{ minHeight: "100vh" }}>
          <Sidebar/>
          <div className="column has-background-light">
            <main>{children}</main>
          </div>
        </div>
    </React.Fragment>
  );
};

export default Layout;
