import React, { useEffect, useState } from 'react'
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";
import TableComponent from './TableComponent.jsx'
import TableComponentModal from './TableComponentModal.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import AlertBox from "../../components/AlertBox";
import axios from "axios";
import "./styles.css";



export default function FormulaAdd() {
    const { Option } = Select;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [aletNote, setAlertNote] = useState(false)
    const [kodeFormula, setKodeFormula] = useState(null)
    const [namaFormula, setNamaFormula] = useState(null)
    const [keterangan, setKeterangan] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEdnDate] = useState(null)
    const [adukan, setAdukan] = useState(null)
    const [pesanAlert, setPesanAlert] = useState(null)
    const [items, setItems] = useState([])
    const [selectedObj, setSelectedObj] = useState([])
    const [rowFix, setRowFix] = useState([])
    useEffect(() => {
        onLoadItem()
    }, [])


    const onLoadItem = async () => {
        await axios.get(`${API_URL}item/getItem`)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    setItems(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const setQtyList = (qty, id) => {
        let obj = selectedObj.find((o) => {
            return o.m_item_id === id
        })
        obj = { ...obj, qt_act: qty }
        let filter = rowFix.filter((o) => {
            return o.m_item_id !== id
        })
        filter.push(obj)
        setRowFix(filter)
    }
    function reset() {
        setKeterangan(null)
        setKodeFormula(null);
        setNamaFormula(null);
        setStartDate(null);
        setEdnDate(null); // Anda mungkin ingin memperbaiki ini ke "setEndDate"
        setAdukan(null);
        setItems([]);
        setSelectedObj([]);
        setRowFix([]);
    }
    return (
        <>
            {
                (aletNote) &&
                <div style={{ marginBottom: 10 }}>
                    <AlertBox msg={"Hallo"} tipe={"success"} handleClose={setAlertNote} />
                </div>
            }
            <Card title="Data Formula" color="#f0f2f5">
                <Spin spinning={loading}>
                    <Row>
                        <Col span={"12"}>
                            <div className="formDevide">
                                <label htmlFor="">Kode Formula</label>
                                <Input placeholder="" value={kodeFormula} onChange={(c) => {
                                    setKodeFormula(c.target.value)
                                }} />
                            </div>
                            <div className="formDevide">
                                <label htmlFor="">Nama Formula</label>
                                <Input placeholder="" value={namaFormula} onChange={(c) => {
                                    setNamaFormula(c.target.value)
                                }} />
                            </div>
                            <div className="formDevide">
                                <label htmlFor="">Kategori Formula</label>
                                {/* <Input readonly placeholder="" value={keterangan} /> */}
                                <Select
                                    value={keterangan}
                                    style={{ width: '100%' }}
                                    options={[{ value: 'MIXER', label: 'MIXER' },
                                    { value: 'EXTRUDER', label: 'EXTRUDER' },
                                    { value: 'PRINTING', label: 'PRINTING' },
                                    { value: 'LAMINATING', label: 'LAMINATING' },
                                    { value: 'PACKING', label: 'PACKING' }
                                    ]}
                                    onChange={(v) => {
                                        setKeterangan(v)
                                    }}
                                />
                            </div>
                            <Row>
                                <Col span={"12"} style={{ paddingRight: 10 }}>
                                    <div className="formDevide">
                                        <label htmlFor="">Berlaku Dari</label>
                                        <Input type='date' placeholder="" value={startDate} onChange={(c) => {
                                            setStartDate(c.target.value)
                                        }} />
                                    </div>
                                </Col>
                                <Col span={"12"} style={{ paddingLeft: 10 }}>
                                    <div className="formDevide">
                                        <label htmlFor="">Berlaku Sampai</label>
                                        <Input type='date' placeholder="" value={endDate} onChange={(c) => {
                                            setEdnDate(c.target.value)
                                        }} />
                                    </div>
                                </Col>
                            </Row>
                            <Button type='primary' danger onClick={() => {
                                let obj = {
                                    startDate: startDate,
                                    endDate: endDate,
                                    kodeFormula: kodeFormula,
                                    namaFormula: namaFormula,
                                    detail: rowFix
                                }
                                setLoading(true)
                                axios
                                    .post(`${API_URL}formula/Create`, {
                                        startDate: startDate,
                                        endDate: endDate,
                                        kodeFormula: kodeFormula,
                                        namaFormula: namaFormula,
                                        keterangan: keterangan,
                                        detail: rowFix
                                    })
                                    .then(function (response) {
                                        console.log(response);
                                        setLoading(false)
                                        if (response.status == 200) {
                                            console.log(response);
                                            setAlertNote(true)
                                            reset()
                                            setPesanAlert(response.msg)
                                            navigate('/formula')
                                        }
                                        setLoading(false)
                                    })
                                    .catch(function (error) {
                                        setLoading(false)
                                        alert(error)
                                        console.log(error);
                                    });
                            }}>Konfirmasi</Button>
                        </Col>
                        <Col span={"12"} style={{ paddingLeft: 20 }}>
                            <Row>
                                <Col span={24}>
                                    <div className="formDevide">
                                        <label htmlFor="">Pilih Item</label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children &&
                                                typeof option.children === 'string' &&
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onDeselect={(v) => {
                                                console.log(v);
                                                let filteredData = selectedObj.filter((item) => item.m_item_id !== v);
                                                setSelectedObj(filteredData);
                                                if (rowFix.length > 0) {
                                                    let dts = rowFix.filter((item) => item.m_item_id !== v);
                                                    setRowFix(dts);
                                                }
                                            }}
                                            onChange={(v) => {
                                                if (v[0]) {
                                                    const selectedIds = v;
                                                    const filteredData = items.filter(item => selectedIds.includes(item.m_item_id));
                                                    const objWithStock = filteredData.map(item => ({
                                                        ...item,
                                                        qt_act: 0
                                                    }));
                                                    setSelectedObj(objWithStock);
                                                }
                                            }}
                                        >
                                            {items.map((item) => (
                                                <Option key={item.m_item_id} value={item.m_item_id}>
                                                    {`${item.kode_item} - ${item.nama_item}`}
                                                </Option>
                                            ))}
                                        </Select>

                                    </div>
                                    {
                                        selectedObj.map((o, index) => (
                                            <Row key={index} style={{ backgroundColor: '#f5f5f5', borderRadius: 10 }}>
                                                <Col span={18} style={{ padding: 10 }}>
                                                    <div className="formDevide">
                                                        <label htmlFor="">Nama Item</label>
                                                        <Input readOnly placeholder="" value={o.nama_item} />
                                                    </div>
                                                </Col>
                                                <Col span={6} style={{ padding: 10 }}>
                                                    <div className="formDevide">
                                                        <label htmlFor="">Qty</label>
                                                        <Input placeholder="" onChange={(c) => {
                                                            setQtyList(c.target.value, o.m_item_id)
                                                        }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Card>
        </>
    )
}
