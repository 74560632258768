import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import TableComponent from './TableComponent.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";
import TextArea from "antd/es/input/TextArea";


function Partner() {
  const [loading, setLoading] = useState(false)
  const [modalPartner, setModalPartner] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [data, setData] = useState([])
  const [partnerId, setPartnerId] = useState(null)
  const [namaPartner, setNamaPartner] = useState(null)
  const [kategori, setKategori] = useState(null)
  const [alamat, setAlamat] = useState(null)
  const [isactive, setIsactive] = useState(null)
  const [username, setUserName] = useState(null)
  const [kat, setKat] = useState([{ value: 1, label: "Vendor" }, { value: 2, label: "Customer" }])

  const navigate = useNavigate();
  useEffect(() => {
    onLoad()
  }, [])
  const handleModal = () => {
    setModalPartner(!modalPartner)
    setModalEdit(false)
  }
  const clear = () => {
    setNamaPartner(null);
    setKategori(null);
    setAlamat(null);
    setIsactive(null);
    setUserName(null)
    setModalPartner(false)
    setModalEdit(false)
  };

  const onLoad = async () => {
    await axios.get(`${API_URL}partner/find`)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setData(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const resetPwd = async () =>{
    axios
      .post(`${API_URL}partner/reset`, {
        username: username
      })
      .then(function (response) {
        console.log(response);
        if (response.status == 200) {
          onLoad()
          clear()
          // handleModal()
        }
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const actionAdd = async () => {
    // console.log('aaa');
    // return
    setLoading(true)
    axios
      .post(`${API_URL}partner/${partnerId ? "update" : "create"}`, {
        nama_partner: namaPartner,
        alamat: alamat,
        isvendor: kategori,
        isactive: 1,
        m_partner_id: partnerId
      })
      .then(function (response) {
        console.log(response);
        if (response.status == 200) {
          //     handleEdit()
          onLoad()
          clear()
          handleModal()
        }
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleEdit = (obj) => {
    console.log(obj);
    if (!modalEdit) {
      setPartnerId(obj.m_partner_id)
      setNamaPartner(obj.nama_partner)
      setAlamat(obj.alamat)
      setKategori(obj.isVendor)
      setUserName(obj.email)
      setModalEdit(true)
    } else {
      clear()
      setModalEdit(false)
    }
  }
  const vModal = () => {
    return (
      <Row>
        <Col span={24}>
          <Spin spinning={loading}>
            <div className="formDevide">
              <label htmlFor="">Nama Partner</label>
              <Input placeholder="" value={namaPartner} onChange={(c) => {
                setNamaPartner(c.target.value)
              }} />
            </div>
            <div className="formDevide">
              <label htmlFor="">Kategori</label>
              <Select
                defaultValue="Pilih"
                style={{
                  width: '100%',
                }}
                value={kategori == 2 ? "Customer" : "Vendor"}
                onChange={(c) => {
                  console.log(c);
                  setKategori(c)
                }}
                options={kat}
              />
            </div>
            {
              kategori == 2 &&
              <>
                <div className="formDevide">
                  <label htmlFor="">(Username akan terbentuk setelah konfirmasi)</label>
                  <Input placeholder="Username akan terbentuk setelah konfirmasi" value={username} readOnly />
                </div>
                <div className="formDevide">
                  <label htmlFor="">Password</label>
                  <Input placeholder="Default (12345)" readOnly/>
                </div>
              </>
            }
            <div className="formDevide">
              <label htmlFor="">Alamat</label>
              <TextArea placeholder="" value={alamat} onChange={(c) => {
                setAlamat(c.target.value)
              }} />
            </div>
            <div className="formDevide">
              <Button type="primary" primary onClick={() => {
                actionAdd()
              }}>Konfirmasi</Button>
              {
                (kategori == 2) &&
                <Button type="primary" danger onClick={() => {
                  resetPwd()
                }}>Reset Password (12345)</Button>
              }
            </div>
          </Spin>
        </Col>
      </Row>
    )
  }
  return (
    <>
      {
        (modalPartner) &&
        <ModalForm content={vModal()} title={"Data Partner"}
          handleModal={handleModal}
          isfooter={'N'} />
      }
      {
        (modalEdit) &&
        <ModalForm content={vModal()} title={"Edit Partner"}
          handleModal={handleEdit}
          isfooter={'N'} />
      }
      <Card title="Data Kategori" color="#f0f2f5">
        <Button type="primary" danger onClick={handleModal}>Tambah Partner</Button>
        <TableComponent datas={data} onEdit={handleEdit} />
      </Card>
    </>

  )
}

export default Partner