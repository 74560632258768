import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
    , ExportOutlined
} from '@ant-design/icons';
import "./styles.css";
import SelectBox from "../../components/SelectBox.jsx";
import TblReject from "./Table/TblReject.jsx";

export default function Reject() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState(null)
    const filter1 = [
        { label: 'All', value: 'All' }
        , { label: 'Extruder', value: 'EXTRUDER' }
        , { label: 'Printing', value: 'PRINTING' }
        , { label: 'Laminating', value: 'LAMINATING' }
        , { label: 'Packing', value: 'PACKING' }
    ]
    useEffect(() => {
        onLoad()
    }, [])
    const onLoad = async () => {
        console.log(filter);
        // return
        await axios.post(`${API_URL}report/reject`, {
            section: filter ? filter.section : null,
            tgl1 : filter ? filter.tgl1 : null,
            tgl2 : filter ? filter.tgl2 : null
        })
            .then(function (res) {
                if (res.status == 200) {
                    console.log(res.data);
                    setData(res.data)
                }
            })
    }
    const extrasFilter = () => {
        return (
            <Row style={{ width: '600px' }}>
                <Col span={8} style={{ paddingRight: 10 }}>
                    <SelectBox width={'100%'} data={filter1} placeholder={"Section"} onChange={(x)=>{
                        setFilter({... filter,section:x})
                        // console.log(x);
                    }}/>
                </Col>
                <Col span={6} style={{ paddingRight: 10 }}>
                    <Input type="date" onChange={(v)=>{
                        setFilter({... filter,tgl1:v.target.value})
                    }}/>
                </Col>
                <Col span={6}>
                    <Input type="date" onChange={(v)=>{
                        setFilter({... filter,tgl2:v.target.value})
                    }} />
                </Col>
                <Col span={4}>
                    <Button style={{ float: 'right' }} type="primary" primary onClick={onLoad}><SearchOutlined /></Button>
                </Col>
            </Row>
        )
    }
    return (
        <div>
            <Card title={"Data Reject"} extra={extrasFilter()}>
                <TblReject datas={data} />
            </Card>
        </div>
    )
}
