import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";


import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import MixerTab from "./TabsComponentDetail/MixerTab.jsx";
import ExtruderTab from "./TabsComponentDetail/ExtruderTab";
import PackagingTab from "./TabsComponentDetail/PackaginTab";
import PrintingTab from "./TabsComponentDetail/PrintingTab";
import LaminatingTab from "./TabsComponentDetail/LaminatingTab";
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined,
} from '@ant-design/icons';
import "./styles.css";
import HasilSPK from "./HasilSPK.js";
import html2pdf from 'html2pdf.js';

export default function DetailSPK() {
    const componentRef = React.useRef();
    const [data, setData] = useState(null)
    const [modal, setModal] = useState(false)
    const [tglSPK, setTglSPK] = useState(null)
    const [nomorSpk, setNomorSPK] = useState(null)
    const [dtlFormula, setDtlFormula] = useState(null)
    const [formula, setFormula] = useState([])
    const [hasilFormula, setHasilFormula] = useState([])
    const [customer, setCustomer] = useState(null)
    const [barang, setBarang] = useState(null)
    const [qty, setQty] = useState(null)
    const [qtyDus, setQtyDus] = useState(null)
    const [qtyPerDus, setQtyPerdus] = useState(null)
    const [berat, setBerat] = useState(null)
    const [dataCustomer, setDataCustomer] = useState([])
    const [dataItem, setDataItem] = useState([])
    const [partner, setPartner] = useState([])
    const [nomorPO, setNomorPO] = useState(null)
    const [tabsContent, setTabsContent] = useState([])
    const [note, setNote] = useState(null)
    const [warna, setWarna] = useState(null)
    const [tipe, setTipe] = useState(null)
    const [lebar, setLebar] = useState(null)
    const [tebal, setTebal] = useState(null)
    const [panjang, setPanjang] = useState(null)
    const [rumusProduksi, setRumusProduksi] = useState(null)
    const { id } = useParams();

    useEffect(() => {
        console.log(id);
        onLoad()
    }, [])
    const handleModal = () => {
        setModal(false)
    }
    const onLoad = async () => {
        console.log(`${API_URL}spk/findOne?spk_id=${id}`);
        await axios.get(`${API_URL}spk/findOne?spk_id=${id}`)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    setData(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const print = () => {
        const content = document.getElementById('pdf-content'); // ID elemen yang akan diubah menjadi PDF
        console.log('a');
        const options = {
            margin: 10,
            filename: 'dokumen.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(content).set(options).outputPdf(pdf => {
            console.log('ac');
            pdf.save();
        });
    }
    const extras = () => {
        return (
            <>
                {
                    (modal) &&
                    <ModalForm content={<HasilSPK keyId={id} data={data} tutup={handleModal} />} title={"Input Hasil Produksi"} handleModal={handleModal} width={500} isfooter={"N"} />
                }
                <Row style={{}}>
                    <Col span={12}>
                        <Button type="primary" danger onClick={() => {
                            window.history.back();
                        }}>
                            <RollbackOutlined /> Kembali
                        </Button>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Button type="primary" onClick={() => {
                            setModal(true)
                        }}>
                            <DeliveredProcedureOutlined /> Proses
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
    const tabSwitch = (obj) => {
        // console.log(obj);

        if (obj.sections == "MIXER") {
            return <MixerTab formulas={obj.dtl.filter((o) => {
                return o.sections == obj.sections
            })} keterangan={data.line_mixing} id={id} warna={data.warna_mixing} note={data.note_mixer} />
        } else if (obj.sections == "EXTRUDER") {
            return <ExtruderTab formulas={obj.dtl.filter((o) => {
                return o.sections == obj.sections
            })} keterangan={data.line_extruder} id={id} note={data.note_extruder} listNat={data.listnat} />
        } else if (obj.sections == "PRINTING") {
            return <PrintingTab formulas={obj.dtl.filter((o) => {
                return o.sections == obj.sections
            })} keterangan={data.line_printing} id={id} note={data.note_printing} />
        } else if (obj.sections == "LAMINATING") {
            return <LaminatingTab formulas={obj.dtl.filter((o) => {
                return o.sections == obj.sections
            })} keterangan={data.line_laminating} id={id} note={data.note_laminating} />
        } else if (obj.sections == "PACKING") {
            return <PackagingTab formulas={obj.dtl.filter((o) => {
                return o.sections == obj.sections
            })} keterangan={data.line_packaging} id={id} note={data.note_packing} />
        }
    }
    return (
        <>
            {
                (data) &&
                <Card title={data.nomor_spk} extra={extras()}>
                    <Row style={{ marginTop: -15 }}>
                        <Col span={4}>
                            <Image
                                width={200} // Atur lebar gambar sesuai kebutuhan
                                src={data.img} // Ganti URL gambar sesuai dengan yang Anda inginkan
                                alt="Image Description"
                            />
                        </Col>
                        <Col span={10}>
                            <Row>
                                <Col span={10} style={{ padding: 10 }}>
                                    <div className="formDevide">
                                        <label htmlFor="" style={{ fontSize: 11 }}>Periode SPK</label>
                                        <p><b>{data.tgl}</b></p>
                                    </div>
                                    <div className="formDevide">
                                        <label htmlFor="" style={{ fontSize: 11 }}>No. Sales Order</label>
                                        <p><b>{data.nomor_po}</b></p>
                                    </div>
                                    <div className="formDevide">
                                        <label htmlFor="" style={{ fontSize: 11 }}>Nama Customer</label>
                                        <p><b>{data.nama_partner}</b></p>
                                    </div>
                                </Col>
                                <Col span={14} style={{ padding: 10 }}>
                                    <div className="formDevide">
                                        <label htmlFor="" style={{ fontSize: 11 }}>Nama Produk</label>
                                        <p><b>{data.nama_product}</b></p>
                                    </div>
                                    <Row>
                                        <Col span={12}>
                                            <div className="formDevide">
                                                <label htmlFor="" style={{ fontSize: 11 }}>Panjang</label>
                                                <p><b>{data.panjang}</b></p>
                                            </div>
                                        </Col>
                                        <Col span={12} style={{ paddingLeft: 5 }}>
                                            <div className="formDevide">
                                                <label htmlFor="" style={{ fontSize: 11 }}>Lebar</label>
                                                <p><b>{data.lebar}</b></p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <div className="formDevide">
                                                <label htmlFor="" style={{ fontSize: 11 }}>Berat</label>
                                                <p><b>{data.berat}</b></p>
                                            </div>
                                        </Col>
                                        <Col span={12} style={{ paddingLeft: 5 }}>
                                            <div className="formDevide">
                                                <label htmlFor="" style={{ fontSize: 11 }}>Qty Produksi</label>
                                                <p><b>{data.qty}</b></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10} style={{ padding: 10 }}>
                            <Tabs type="card">
                                {
                                    data.formula.map((item, index) => (
                                        <Tabs.TabPane tab={item.sections} key={index}>
                                            <div style={{ paddingLeft: 10 }}>
                                                {tabSwitch(item)}
                                            </div>
                                        </Tabs.TabPane>
                                    ))
                                }
                            </Tabs>
                        </Col>
                    </Row>
                </Card>
            }
        </>
    )
}
