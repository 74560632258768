import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "./global"
const initialState = {
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}
const url = API_URL
export const LoginUser = createAsyncThunk("user/LoginUser", async (user, thunkAPI) => {
    try {
        const response = await axios.post(`${url}auth/login`, {
            email: user.email,
            password: user.password
        });
        localStorage.removeItem('dataLogin');
        localStorage.removeItem('user');
        localStorage.setItem('dataLogin', JSON.stringify(response.data))
        localStorage.setItem('user', JSON.stringify(response.data))
        return response.data;
    } catch (error) {
        console.log(`***${error}`);
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});



export const getMe = createAsyncThunk("user/getMe", async (additionalParam, thunkAPI) => {
    try {
        console.log('woiii', additionalParam);
        const response = await axios.get(`${url}auth/me`);
        if (response.data) {
            // set menu di localStorage
            const appMenu = localStorage.getItem("appMenu");
            if (appMenu) {
            } else {
                console.log(`Set Pertama kali menu ..........`, response.data);
                localStorage.setItem("appMenu", response.data.menu);
                localStorage.setItem("user", response.data);
            }
        }
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const LogOut = createAsyncThunk("user/LogOut", async () => {
    await axios.delete(`${url}auth/logout`);
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(LoginUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(LoginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(LoginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })

        // Get User Login
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
    }
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;