import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip, DatePicker
} from "antd";
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import MixerTab from "./TabsComponentDetail/MixerTab.jsx";
import ExtruderTab from "./TabsComponentDetail/ExtruderTab";
import PackagingTab from "./TabsComponentDetail/PackaginTab";
import PrintingTab from "./TabsComponentDetail/PrintingTab";
import LaminatingTab from "./TabsComponentDetail/LaminatingTab";
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined,
} from '@ant-design/icons';
import "./styles.css";
import PrintComponent from '../../components/PrintComponent';

export default function HasilSPK({ keyId, data, tutup }) {
    const [obj, setObj] = useState(null)
    const [sec, setSec] = useState(null)
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    const { RangePicker } = DatePicker;

    const konfirmasi = () => {

    }
    const isian = (sec) => {
        if (sec == "MIXER") {
            return (
                <>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Shift</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, shift: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Tanggal</label>
                                <Input type="date" onChange={(e) => {
                                    setObj({ ...obj, tgl: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Jam Mulai</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, start: e.target.value })
                                }} />
                                {/* <DatePicker style={{ width: '100%' }} renderExtraFooter={() => 'extra footer'}
                                    format="HH:mm"
                                    showTime onChange={(v) => {
                                        console.log(v);
                                    }} /> */}
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Jam Selesai</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, end: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Co Aditif</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, aditif: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Koordinator</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, koordinator: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Adukan (kg)</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, adukan: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Sisa (Kg)</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, sisa: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                </>
            )
        } else if (sec == "EXTRUDER" || sec == "LAMINATING") {
            return (
                <>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Shift</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, shift: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Tanggal</label>
                                <Input type="date" onChange={(e) => {
                                    setObj({ ...obj, tgl: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Jam Mulai</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, start: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Jam Selesai</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, end: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Operator</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, operator: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Helper</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, koordinator: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Qty Lbr</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, qty: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Reject (Kg)</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, reject: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Berat /m1</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, berat: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            {/* <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Cycle Time</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, cycle: e.target.value })
                                }} />
                            </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            {/* <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>RPM</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, rpm: e.target.value })
                                }} />
                            </div> */}
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>

                        </Col>
                    </Row>
                </>
            )
        } else if (sec == "PRINTING") {
            return (
                <>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Shift</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, shift: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Tanggal</label>
                                <Input type="date" onChange={(e) => {
                                    setObj({ ...obj, tgl: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Jam Mulai</label>
                                <Input type="datetime" onChange={(e) => {
                                    setObj({ ...obj, start: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Operator</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, operator: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Koordinator</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, koordinator: e.target.value })
                                }} />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Qty/Dus</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, qtydus: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Ext/Lbr</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, extlbr: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Qty Lbr</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, qty: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Reject (Kg)</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, reject: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                </>
            )
        } else if (sec == "PACKING") {
            return (
                <>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Tanggal</label>
                                <Input type="date" onChange={(e) => {
                                    setObj({ ...obj, tgl: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Jam</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, start: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Operator</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, operator: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Koordinator</label>
                                <Input onChange={(e) => {
                                    setObj({ ...obj, koordinator: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Qty (Satuan)</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, qty: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Qty Dus</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, qty_dus: e.target.value })
                                }} />
                            </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                            <div className="formDevide">
                                <label htmlFor="" style={{ fontSize: 11 }}>Reject (satuan)</label>
                                <Input type="number" onChange={(e) => {
                                    setObj({ ...obj, reject: e.target.value })
                                }} />
                            </div>
                        </Col>
                    </Row>
                </>
            )
        } else {
            return (
                <Row>
                    <Col span={12} style={{ padding: 7 }}>

                    </Col>
                    <Col span={12} style={{ padding: 7 }}>

                    </Col>
                </Row>
            )
        }
    }
    const trans = () => {
        let objx = { ...obj, key_id: id, section: sec }
        console.log(JSON.stringify(objx));
        // return
        axios
            .post(`${API_URL}spk/hasil`, {
                objek: objx
            })
            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    alert(response.data.msg)
                    tutup()
                }
            })
            .catch(function (error) {
                setLoading(false)
            });
    }
    return (
        <div>
            <Spin spinning={loading}>
                <Row>
                    <Col span={12}>
                        <div className="formDevide">
                            <label htmlFor="" style={{ fontSize: 11 }}>Nomor SPK</label>
                            <p><b>{data.nomor_spk}</b></p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="formDevide">
                            <label htmlFor="" style={{ fontSize: 11 }}>Nama Product</label>
                            <p><b>{data.nama_product}</b></p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="formDevide">
                            <label htmlFor="" style={{ fontSize: 11 }}>Section</label>
                            <Select
                                onChange={(v) => {
                                    setObj(null)
                                    setSec(v)
                                }}
                                style={{ width: '100%' }}
                                options={[
                                    { label: 'MIXER', value: "MIXER" },
                                    { label: 'EXTRUDER', value: "EXTRUDER" },
                                    { label: 'PRINTING', value: "PRINTING" },
                                    { label: 'LAMINATING', value: "LAMINATING" },
                                    { label: 'PACKING', value: "PACKING" },
                                ]}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {isian(sec)}
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ padding: 7 }}>
                        <Button type="primary" primary onClick={trans}>konfirmasi</Button>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
