import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
    , ExportOutlined
} from '@ant-design/icons';
import "./styles.css";
import SelectBox from "../../components/SelectBox.jsx";
import TblMixer from "./Table/TblMixer.jsx";
import TblExtruder from "./Table/TblExtruder.jsx";
import TblPrinting from "./Table/TblPrinting.jsx";
import TblPacking from "./Table/TblPacking.jsx";
import TblLaminanting from "./Table/TblLaminating.jsx";

export default function LaporanHasilSPK() {
    const [data, setData] = useState([])
    const [sec, setSec] = useState([
        { label: 'MIXER', value: 'MIXER' },
        { label: 'EXTRUDER', value: 'EXTRUDER' },
        { label: 'PRINTING', value: 'PRINTING' },
        { label: 'LAMINATING', value: 'LAMINATING' },
        { label: 'PACKING', value: 'PACKING' }
    ])
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const filter1 = [{ label: 'Waiting', value: 'Waiting' }
        , { label: 'On Progress', value: 'On Progress' }
        , { label: 'Complete', value: 'Complete' }]

    useEffect(() => {
        // onLoad()
    }, [])

    const onLoad = async () => {
        console.log(filter);
        // return
        setLoading(true)
        await axios.post(`${API_URL}report/spkResult`, {
            section: filter ? filter.section : null,
            tgl1: filter ? filter.tgl1 : null,
            tgl2: filter ? filter.tgl2 : null
        })
            .then(function (res) {
                setLoading(false)
                if (res.status == 200) {
                    console.log(res.data);
                    setData(res.data)
                }
            })
            .catch(function (error) {
                setLoading(false)
                alert(error.message)
                console.log(error);
            });
    }
    const onDel = async (v) => {
        console.log(v.spk_result_id);
        setLoading(true)
        await axios.post(`${API_URL}spk/deleteResult`, {
            spk_result_id: v.spk_result_id
        })
            .then(function (res) {
                setLoading(false)
                if (res.status == 200) {
                    console.log(res.data);
                    onLoad()
                }
            })
    }
    const dataTable = () => {
        if (filter.section == "MIXER") {
            return <TblMixer datas={data} onEdit={onDel} />
        } else if (filter.section == "EXTRUDER") {
            return <TblExtruder datas={data} onEdit={onDel} />
        } else if (filter.section == "PRINTING") {
            return <TblPrinting datas={data} onEdit={onDel} />
        } else if (filter.section == "PACKING") {
            return <TblPacking datas={data} onEdit={onDel} />
        } else if (filter.section == "LAMINATING") {
            return <TblLaminanting datas={data} onEdit={onDel} />
        } else {
            return null
        }
    }
    const extrasFilter = () => {
        return (
            <Row style={{ width: '900px' }}>
                <Col span={15}>
                    <Row>
                        <Col span={8} style={{ paddingRight: 7 }}>
                            <Input type="date" onChange={(v) => {
                                setFilter({ ...filter, tgl1: v.target.value })
                            }} />
                        </Col>
                        <Col span={8} style={{ paddingRight: 7 }}>
                            <Input type="date" onChange={(v) => {
                                setFilter({ ...filter, tgl2: v.target.value })
                            }} />
                        </Col>
                        <Col span={8} style={{ paddingRight: 7 }}>
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                placeholder="Pilih Section"
                                optionFilterProp="children"
                                onChange={(v) => {
                                    setData([])
                                    setFilter({ ...filter, section: v })
                                }}
                                options={sec}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={9}>
                    <Row>
                        <Col span={16}>
                            <Input placeholder="Cari Nomor SPK" />
                        </Col>
                        <Col span={4}>
                            <Button style={{ float: 'right' }} type="primary" danger><ExportOutlined /></Button>
                        </Col>
                        <Col span={4}>
                            <Button onClick={onLoad} style={{ float: 'right' }} type="primary" primary><SearchOutlined /></Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    return (
        <div>
            <Card title={"Data Hasil SPK"} extra={extrasFilter()} >
                <Spin spinning={loading}>
                    {(data && filter) && dataTable()}
                </Spin>
            </Card>
        </div>
    )
}
