import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import ModalForm from '../../../components/Modal/index.jsx';
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";


export default function ExtruderTab({ keterangan, formulas, additional,
  resetFormula, panjang, qty_produksi, line, masterFormula, barang, berat, listNat }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [objFormula, setObjFormlua] = useState(null)

  const [rm, setRm] = useState([])
  const [openMod, setOpenMod] = useState(false)
  const [objMod, setObjMod] = useState(null)
  const [pergantian, setPergantian] = useState(null)
  const [net, setNat] = useState(null)

  const resetMod = (obj) => {
    setOpenMod(false)
    setObjMod(null)
    setPergantian(null)
  }
  const setMasterFormula = (cb, sec) => {
    let awal = masterFormula.filter((o) => {
      return o.sections !== sec
    })
    cb.map(item => {
      awal.push(item)
    });
    console.log(`*** RESET EXTRUDER ***`);
    resetFormula(awal)
  }
  function angka(angka) {
    // Jika mengandung koma, gunakan toFixed(2)
    if (angka.toString().includes('.')) {
      return parseFloat(angka).toFixed(2);
    } else {
      // Jika tidak mengandung koma, biarkan saja
      return angka.toString();
    }
  }
  useEffect(() => {
    // let px = formulas.filter(item => item.qty > 0);
    // let br = px.map((o) => {
    //   let qtyp = o.qty
    //   return o = { ...o, qty: qtyp * parseFloat(panjang / 100) * parseFloat(qty_produksi) }
    // })
    // console.log('cek disini....',br);
    firstLoad()
  }, [])
  const firstLoad = async () => {
    console.log(`${API_URL}product/getFormulaExtruder?barang=${barang}`)
    await axios
      .get(`${API_URL}product/getFormulaExtruder?barang=${barang}`, {})
      .then(function (response) {
        if (response.status == 200) {
          if (response.data.length > 0) {
            let oc = response.data
            let br = formulas
            for (let i = 0; i < oc.length; i++) {
              let qty_nya = parseFloat(panjang) * 1 * parseFloat(qty_produksi) / 100
              // console.log(oc[i].qty, '===', qty_nya);

              let obj = {
                amount: oc[i].amount, formula_detail_id: oc[i].extruder_item_id,
                formula_id: oc[i].formula_id,
                kode_formula: formulas[0].kode_formula,
                kode_item: oc[i].kode_item,
                kode_section: formulas[0].kode_section,
                m_section_id: formulas[0].m_section_id,
                nama_formula: formulas[0].nama_formula,
                nama_item: oc[i].kode_item,
                qty: oc[i].qty * qty_nya,
                qty_std: oc[i].qty,
                satuan: oc[i].satuan,
                sections: "EXTRUDER"
              }
              // console.log(obj);
              br.push(obj)
            }
            let uniqueData = [...new Set(br.map(item => item.formula_detail_id))].map(id => {
              // Memilih entri pertama yang cocok dengan formula_detail_id
              return br.find(item => item.formula_detail_id === id);
            });
            // console.log(br);
            console.log(uniqueData, 'woooo-----');
            setMasterFormula(uniqueData, 'EXTRUDER')
            setDtlFormula(uniqueData)
          } else {
            setMasterFormula(formulas, 'EXTRUDER')
            setDtlFormula(formulas)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const combo = () => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <p>Nama Item</p>
            <b>{objMod && objMod.nama_item}</b>
          </Col>
          <Col span={12}>
            <p>Qty</p>
            <b>{objMod && Math.ceil(objMod.qty)}</b>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>Komponen Pengganti</label>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Pilih Customer"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(v) => {
                // setCustomer(v)
                let rmobj = rm.find((o) => {
                  return o.m_item_id == v
                })
                setPergantian(rmobj)
              }}
            >
              {rm.map(item => (
                <Option key={item.m_item_id} value={item.m_item_id}>
                  {item.nama_item}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col span={12} style={{ padding: 1 }}>
            <Button type='primary' style={{ width: '100%' }} danger onClick={() => {
              resetMod()
            }}>Batal</Button>
          </Col>
          <Col span={12} style={{ padding: 1 }}>
            <Button type='primary' style={{ width: '100%' }} primary onClick={() => {
              console.log(pergantian);

              let pc = dtlFormula.map((o) => {
                if (o.formula_detail_id == objMod.key_id) {
                  console.log(objMod.key_id);
                  return { ...o, kode_item: pergantian.kode_item, nama_item: pergantian.nama_item }
                }
                return o
              })
              // console.log(pc);
              setDtlFormula(pc)
              setMasterFormula(pc, 'EXTRUDER')
              resetMod()
            }}>Pilih</Button>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <div>
      {
        (openMod) &&
        <ModalForm content={combo()} isfooter={'N'} handleModal={resetMod} title={"Edit Komponen RM"} />
      }
      <Row style={{ marginBottom: 10 }}>
        <Col span={12} style={{ paddingRight: 10 }}>
          <p>Nama Formula</p>
          <p><b>{formulas[0].nama_formula}</b></p>
        </Col>
        <Col span={12}>
          <Input placeholder='Line Extruder' onChange={(v) => {
            line(v.target.value)
          }} />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={8} style={{ paddingRight: 10 }}>
          <p><b>List</b></p>
          <Input placeholder='List' onChange={(v) => {
            setNat(v.target.value)
            listNat(v.target.value)
          }} />
        </Col>
        <Col span={12}>
          {/* <Input placeholder='Isian Nat Extruder' onChange={(v) => {
            line(v.target.value)
          }} /> */}
        </Col>
      </Row>
      {/* <Row style={{marginBottom:10}}>
        <Col span={6} style={{paddingRight:10}}>
          <p>Hot Stamp</p>
          {combo()}
        </Col>
        <Col span={6} style={{paddingRight:10}}>
          <p>Laminate</p>
          {combo()}
        </Col>
        <Col span={6} style={{paddingRight:10}}>
          <p>Pita Nat</p>
          {combo()}
        </Col>
        <Col span={6}>
          <p>Pita List</p>
          {combo()}
        </Col>
      </Row> */}
      <Row style={{ backgroundColor: '#edf5fd', padding: 8, marginBottom: 1 }}>
        <Col span={3}>
          {additional.nama_produk}
        </Col>
        <Col span={12}>
          {additional.nama_produk}
        </Col>
        <Col span={3}>
          {additional.satuan}
        </Col>
        <Col span={3}>
          -
        </Col>
        <Col span={3}>
          {additional.qty}
        </Col>
      </Row>
      {
        dtlFormula.map((o, index) => {
          console.log(o);
          return (
            <>
              <Row key={index} style={{ backgroundColor: index % 2 ? '#ffff' : '#fafafa', padding: 8, marginBottom: 1 }}>
                <Col span={3}>
                  {o.kode_item}
                </Col>
                <Col span={12}>
                  {/* <Button onClick={() => {
                    console.log(o.kode_item, o.formula_detail_id);
                    setObjMod({
                      key_id: o.formula_detail_id,
                      nama_item: o.nama_item,
                      qty: o.qty
                    })
                    setOpenMod(true)
                  }}>{o.nama_item}</Button> */}
                  {o.nama_item}
                </Col>
                <Col span={3}>
                  {o.satuan}
                </Col>
                <Col span={3}>
                  {angka(o.qty_std)}
                </Col>
                <Col span={3}>
                  {angka(o.qty)}
                </Col>
              </Row>
            </>
          )
        })
      }
    </div>
  )
}
