import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import { Card, Input, Spin, Row, Col, Select
    , Button,Space,Alert } from "antd";
import TableComponent from './TableComponent.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";



function Kategori() {
  const [data,setData]  = useState([])
  const [modalKategori,setModalKategori]  = useState(false)
  const [kategori,setKategori]  = useState(null)
  const [loading,setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  
  useEffect(()=>{
    onLoad()
  }, [])
  const handleModal = () =>{
    setModalKategori(!modalKategori)
  }
  const vModal = () => {
    return (
        <Row>
            <Col span={24}>
                <Spin spinning={loading}>
                    <div className="formDevide">
                        <label htmlFor="">Nama Kategori</label>
                        <Input placeholder="" value={kategori} onChange={(c)=>{
                            setKategori(c.target.value)
                        }} />
                    </div>
                </Spin>
            </Col>
        </Row>
    )
  }
  const onEdit = (v) =>{
    console.log(v);
    axios
    .post(`${API_URL}item/delKategori`,{
        id:v.m_kategori_id
    })
    .then(function (response) {
        console.log(response);
        if(response.status == 200){
            onLoad()
        }
        setLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
  }
  const actionAdd = ()=>{
    // console.log('as');
    // return
    axios
    .post(`${API_URL}item/createKategori`,{
        kategori:kategori,
        isactive:1
    })
    .then(function (response) {
        console.log(response);
        if(response.status == 200){
            setKategori(null)
            onLoad()
            handleModal()
        }
        setLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
}
  const onLoad = async () =>{
    await axios.get(`${API_URL}item/getKategori`)
            .then(function (response) {
                console.log(response);
                if (response.status == 200) {
                    console.log(response.data);
                    setData(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
  }
  return (
    <>
        {
            (modalKategori) && 
            <div>
                <ModalForm title={"Tambahan Kategori"}  handleModal={handleModal} 
                content={vModal()} onOke={actionAdd}/>
            </div>
        }
        <Card title="Data Kategori" color="#f0f2f5">
            <Button type="primary" danger onClick={handleModal}>Tambah Kategori</Button>
            <TableComponent datas={data} onEdit={onEdit}/>
        </Card>
    </>
  )
}

export default Kategori