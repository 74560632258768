import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../logo.png";
import interindo from "../interindo.png";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import {
  Button,
  Layout,
  Menu,
} from 'antd';
const { Header, Content, Sider } = Layout;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    console.log('asd');
    dispatch(LogOut());
    dispatch(reset());
    localStorage.setItem("user", null);
    localStorage.setItem("appMenu", null);

    navigate("/");
  };
  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffff',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {/* <b>PT. Surya Plafon Interindo</b> */}
      <div className="demo-logo" >
        <img src={interindo} alt="" width="150" height="30" style={{ marginTop: 27, marginLeft: 0 }} srcset="" />
      </div>
      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Button onClick={logout} type="primary">
                Log out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Header>
    // <div>
    //   <nav
    //     className="navbar is-fixed-top has-shadow is-info"
    //     role="navigation"
    //     aria-label="main navigation"
    //   >
    //     <div className="navbar-brand">
    //       <NavLink to="/dashboard" className="navbar-item">
    //         {/* <img src={logo} width="112" height="28" alt="logo" /> */}
    //         <b style={{
    //           fontSize : 23
    //         }}>PT. Surya Plafon Interindo</b>
    //       </NavLink>
    //       <a
    //         href="!#"
    //         role="button"
    //         className="navbar-burger burger"
    //         aria-label="menu"
    //         aria-expanded="false"
    //         data-target="navbarBasicExample"
    //       >
    //         <span aria-hidden="true"></span>
    //         <span aria-hidden="true"></span>
    //         <span aria-hidden="true"></span>
    //       </a>
    //     </div>

    //     <div id="navbarBasicExample" className="navbar-menu">
    //       <div className="navbar-end">
    //         <div className="navbar-item">
    //           <div className="buttons">
    //             <button onClick={logout} className="button is-danger is-rounded">
    //               Log out
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
  );
};

export default Navbar;
