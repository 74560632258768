import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";


import { getMe } from "../../features/authSlice";
import {
  Card, Input, Spin, Row, Col, Select, Tabs, Image
  , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import {
  SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
  , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
  , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined,
} from '@ant-design/icons';
import "./styles.css";
export default function BarangKeluar() {
  const [data, setData] = useState([])
  const navigate = useNavigate();
  const [section, setSection] = useState(null)
  const [nomorSpk, setNomorSPK] = useState(null)
  const [nama, setNama] = useState(null)
  const [shift, setShift] = useState(null)
  const [loading, setLoading] = useState(false)
  const header = (obj) => {
    return (
      <Row>
        <Col span={12}>
          <div className="formDevide">
            <p className="formDevide">Nomor SPK</p>
            <p className="isi"><b>{data[0].nomor_spk}</b></p>
          </div>
          <div className="formDevide">
            <p className="formDevide">Nama Barang</p>
            <p className="isi"><b>{data[0].nama_product}</b></p>
          </div>
        </Col>
        <Col span={12}>
          <div className="formDevide">
            <p className="formDevide">Section</p>
            <p className="isi"><b>{section}</b></p>
          </div>
          <div>
            <div style={{ float: 'left', paddingRight: 10 }}>
              <p className="formDevide">Line</p>
              <p className="isi"><b>{(() => {
                if (section === "MIXER") {
                  return data[0].line_mixing
                } else if (section === "EXTRUDER") {
                  return data[0].line_extruder
                } else if (section === "PRINTING") {
                  return data[0].line_printing
                } else if (section === "LAMINATING") {
                  return data[0].line_laminating
                } else {
                  return data[0].packing
                }
              })()}</b></p>
            </div>
            <div style={{ float: 'right', paddingRight: 10 }}>
              <Button type="primary" danger onClick={()=>{
                window.history.back();
              }}>Kembali</Button>
              <Button type="primary" primary onClick={()=>{
                proses()
              }}>Proses</Button>
            </div>
          </div>

        </Col>
      </Row>
    )
  }
  const cari = async () => {
    await axios.get(`${API_URL}spk/findFormulaSec?nomorspk=${nomorSpk}&section=${section}`)
      .then(function (response) {
        if (response.status == 200) {
          let dt = response.data.filter((o) => {
            return o.qty > 0
          })
          dt = dt.map((o, index) => {
            return { ...o, urutan: index + 1 }
          })
          // console.log(dt[0].urutan);
          setData(dt.sort(function (a, b) {
            return a.urutan - b.urutan;
          }))
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const proses = async () => {
    let dataPush = data.filter(item => item.hasOwnProperty('request'));
    // console.log(JSON.stringify(obj));
    setLoading(true)
    await axios.post(`${API_URL}item/keluarItem`, {
      header : {
        nomorSpk:nomorSpk,
        section:section,
        nama:nama,
        shift:shift
      },
      detail : dataPush
    })
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setLoading(false)
          window.history.back();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoading(false)
  }
  return (
    <>
      <Spin spinning={loading}>
      <Row>
        <Col span={10}>
          <Row>
            <Col span={10} style={{ padding: 10 }}>
              <div className="formDevide">
                <label htmlFor="" style={{ fontSize: 10 }}><b>Nomor SPK</b></label><br />
                <Input value={nomorSpk} onChange={(o) => {
                  setNomorSPK(o.target.value)
                }} />
              </div>
            </Col>
            <Col span={11} style={{ padding: 10 }}>
              <label htmlFor="" style={{ fontSize: 10 }}><b>Pilih Section</b></label><br />
              <Select
                onChange={(V) => {
                  setSection(V)
                }}
                style={{ width: '100%' }}
                options={[{ label: "MIXER", value: "MIXER" }
                  , { label: "EXTRUDER", value: "EXTRUDER" }
                  , { label: "PRINTING", value: "PRINTING" }
                  , { label: "LAMINATING", value: "LAMINATING" }
                  , { label: "PACKING", value: "PACKING" },]}
              />
            </Col>
            <Col span={3} >
              <Button style={{ marginTop: 30, float: 'left' }} onClick={() => {
                cari()
              }} type="primary" primary ><SearchOutlined /></Button>
            </Col>
          </Row>
          <Row style={{ marginTop: -20 }}>
            <Col span={14} style={{ padding: 10 }}>
              <div className="formDevide">
                <label htmlFor="" style={{ fontSize: 10 }}><b>Nama Requestor</b></label><br />
                <Input value={nama} onChange={(o) => {
                  setNama(o.target.value)
                }} />
              </div>
            </Col>
            <Col span={10} style={{ padding: 10 }}>
              <div className="formDevide">
                <label htmlFor="" style={{ fontSize: 10 }}><b>Shift</b></label><br />
                <Input value={shift} onChange={(o) => {
                  setShift(o.target.value)
                }} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          {
            (data.length > 0) ?
              <>
                {header()}
                <Row style={{ padding: 7, backgroundColor: '#555555', color: '#ffff', borderRadius: 5 }}>
                  <Col span={2}>No</Col>
                  <Col span={5}>Kode Item</Col>
                  <Col span={7}>Nama Item</Col>
                  <Col span={3}>Satuan</Col>
                  <Col span={3}>Qy</Col>
                  <Col span={4}>Request</Col>
                </Row>
                <div>
                  {
                    data.map((o, index) => (
                      <Row key={index} style={{ padding: 7, paddingBottom: 15, backgroundColor: index % 2 === 0 ? '#ffff' : '#ececec', color: '#100d33', borderRadius: 0 }}>
                        <Col span={2}>{index + 1}</Col>
                        <Col span={5}>{o.kode_item}</Col>
                        <Col span={7}>{o.nama_item}</Col>
                        <Col span={3}>{o.satuan}</Col>
                        <Col span={3}>{o.qty}</Col>
                        <Col span={4}>
                          <Input type="number" onChange={(e) => {
                            let pn = data.filter((v) => {
                              return v.spk_detail_formula_id !== o.spk_detail_formula_id
                            })
                            let dt = { ...o, request: e.target.value }
                            pn.push(dt)
                            // console.log(pn.sort(function (a, b) {
                            //   return a.urutan - b.urutan;
                            // }));
                            setData(pn.sort(function (a, b) {
                              return a.urutan - b.urutan;
                            }))
                          }} />
                        </Col>
                      </Row>
                    ))
                  }
                </div>
              </>
              : null
          }
        </Col>
      </Row>
      </Spin>
    </>
  )
}
