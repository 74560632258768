import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal.jsx";
import {
  SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
  , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
  , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea.js';

export default function PackagingTab({ keterangan, formulas, id, setId,note }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState(formulas)
  const [objFormula, setObjFormlua] = useState(null)
  const [notes, setNotes] = useState(note)

  useEffect(() => {

  }, [])
  return (
    <div>
      <>
        <Row style={{ marginTop: 20, paddingLeft: 7, borderRadius: 5 }}>
          <Col span={6}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Kode Formula</b></label><br />
              <label htmlFor="">{formulas.length > 0 ? formulas[0].kode_formula : ''}</label>
            </div>
          </Col>
          <Col span={6}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Nama Formula</b></label><br />
              <label htmlFor="">{formulas.length > 0 ? formulas[0].nama_formula : ''}</label>
            </div>
          </Col>
          <Col span={6}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Line</b></label><br />
              <label htmlFor="">{ keterangan !== 'null' ? keterangan : ''}</label>
            </div>
          </Col>
          <Col span={6}  style={{textAlign:'right'}}>
            <Button type="primary" onClick={async () => {
              window.open(`${API_URL}spk/cetak?spk_id=${id}&section=PACKING`, '_blank');
            }}>
              <PrinterOutlined /> Cetak
            </Button>
          </Col>
        </Row>
        {/* darisisni */}
        <Row>
          <Col span={20}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Catatan & Keterangan</b></label><br />
              <TextArea value={notes} onChange={(c) => { setNotes(c.target.value) }} />
            </div>
          </Col>
          <Col span={4}>
            <Button type='primary' danger style={{ marginTop: 17, marginLeft: 7,float:'right' }}
              onClick={async () => {
                // console.log(id,notes);
                // return
                axios
                  .post(`${API_URL}spk/addnote`, {
                    spk_id : id,
                    sec : 'PACKING',
                    note : notes
                  })
                  .then(function (response) {
                    if (response.status == 200) {
                      alert(response.data.msg)
                    }
                  })
                  .catch(function (error) {
                  });
              }}
            >Simpan</Button>
          </Col>
        </Row>
        {/* sampe sini */}
        {
          formulas.map((o, index) => {
            return <Row key={index} style={{ padding: 5, backgroundColor: '#e5e5e5', marginBottom: 1, borderRadius: 5 }}>
              <Col span={12}>{o.nama_item}</Col>
              <Col span={4}>{o.satuan}</Col>
              <Col span={8}>{o.qty}</Col>
            </Row>
          })
        }
      </>
    </div>
  )
}
