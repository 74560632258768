import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";


export default function MixerTab({ keterangan, formulas, jmladukan, line
  , masterFormula,reSetFormula }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [objFormula, setObjFormlua] = useState(null)
  // hitungan
  const [jumlahAdukan,setJumlahAdukan] = useState(null)
  const [adukanPerlot,setAdukanPerlot] = useState(null)
  const [jmlLot,setJmlLot] = useState(1)
  const [qtyAdukan,setQtyAdukan] = useState(0)
  function angka(angka) {
    // Jika mengandung koma, gunakan toFixed(2)
    if (angka.toString().includes('.')) {
      return parseFloat(angka).toFixed(2);
    } else {
      // Jika tidak mengandung koma, biarkan saja
      return angka.toString();
    }
  }
  useEffect(() => {
    setDtlFormula(formulas)
    const totalStokAkhir = formulas.reduce((total, item) => total + item.qty, 0);
    setAdukanPerlot(totalStokAkhir)
    
    setQtyAdukan(jmladukan)
    //setting rumus disini
    let lots = Math.ceil(jmladukan/totalStokAkhir)
    setJmlLot(lots)
    setJumlahAdukan(totalStokAkhir * lots)
    // console.log(lots,'JML LOT');
    let cb = masterFormula.filter((o)=>{
      return o.sections == 'MIXER'
    })
    cb = cb.map(item => {
      let op = {... item, qty : item.qty * lots,std:item.qty}
      return op
    });
    setMasterFormula(cb,'MIXER')
  }, [])
  const setMasterFormula = (cb,sec) =>{
    let awal = masterFormula.filter((o)=>{
      return o.sections !== sec
    })
    cb.map(item => {
      awal.push(item)
    });
    console.log(`*** RESET MIXER ***`);
    reSetFormula(awal)
  }
  return (
    <div>
      <Row style={{marginBottom:10}}>
        <Col span={6}>
          <p>Nama Formula</p>
          <p><b>{formulas[0].nama_formula}</b></p>
        </Col>
        <Col span={6}>
          <p>Total Adukan (Kg)</p>
          <p><b>{jumlahAdukan}</b></p>
        </Col>
        <Col span={6}>
          <p>Adukan/Lot</p>
          <p><b>{adukanPerlot}</b></p>
        </Col>
        <Col span={2}>
          <p>Lot</p>
          <p><b>{jmlLot}</b></p>
        </Col>
        <Col span={4}>
          <Input placeholder='Mesin Mixer' onChange={(v)=>{
            line(v.target.value)
          }}/>
        </Col>
      </Row>
      {
        dtlFormula.map((o,index) => {
          return (
            <Row key={index} style={{ backgroundColor: index % 2 ? '#ffff': '#fafafa', padding: 8,marginBottom:1 }}>
              <Col span={3}>
                {o.kode_item}
              </Col>
              <Col span={12}>
                {o.nama_item}
              </Col>
              <Col span={3}>
                {o.satuan}
              </Col>
              <Col span={3}>
                {angka(o.qty)}
              </Col>
              <Col span={3}>
                {angka(o.qty * (jmlLot ? jmlLot : 1))}
              </Col>
            </Row>
          )
        })
      }
    </div>
  )
}
