import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
    , ExportOutlined
} from '@ant-design/icons';
import "./styles.css";
import TblMutasi from "./Table/TblMutasi.jsx";

export default function StockRm() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState(null)
    const filter1 = [{ label: 'Waiting', value: 'Waiting' }
        , { label: 'On Progress', value: 'On Progress' }
        , { label: 'Complete', value: 'Complete' }]

    useEffect(() => {
        onLoad()
    }, [])
    const onLoad = async () => {
        await axios.post(`${API_URL}report/mutasi`, {
            status : filter ? filter.status : null
        })
            .then(function (res) {
                if (res.status == 200) {
                    console.log(res.data);
                    setData(res.data)
                }
            })
    }
    // const extrasFilter = () => {
    //     return (
    //         <Row style={{ width: '400px' }}>
    //             <Col span={10} style={{ paddingRight: 10 }}>
    //                 <SelectBox width={'100%'} data={filter1} placeholder={"Progress SPK"} onChange={(v)=>{
    //                     setFilter({... filter,status : v})
    //                 }} />
    //             </Col>
    //             <Col span={10}>
    //                 <Input placeholder="Cari Sesuatu" />
    //             </Col>
    //             <Col span={4}>
    //                 <Button onClick={onLoad} style={{ float: 'right' }} type="primary" primary><SearchOutlined /></Button>
    //             </Col>
    //         </Row>
    //     )
    // }
    return (
        <div>
            <Card title={"Stock & Mutasi"}>
                <TblMutasi datas={data} />
            </Card>
        </div>
    )
}
