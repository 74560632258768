import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProductList from "../../components/ProductList";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
  Card, Input, Spin, Row, Col, Select, Checkbox
  , Button, Space, Alert, message, Upload, Image
} from "antd";

import TableComponent from "./TableComponent";
import ModalForm from "../../components/Modal";
import axios from "axios";
import { API_URL } from '../../features/global.js'
import ModalFormula from "./ModalFormula";

// import TableData from "./TableData";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
  const [openModalFormula, setOpenModalFormula] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const { isError } = useSelector((state) => state.auth);
  const [data, setData] = useState([])
  const [uom, setUom] = useState([])
  const [molding, setMolding] = useState([])
  const [moldingPilih, setMoldingPilih] = useState([])
  const [listKategori, setListKategori] = useState([])
  const [loading, setLoading] = useState(false)
  const [uomPilihan, setUomPilihan] = useState([])
  const [itemExt, setItemExt] = useState([])
  const [mProductId, setMproductId] = useState(null)
  const [isActive, setIsactive] = useState('1')
  const [kodeBarang, setKodeBarang] = useState(null)
  const [namaBarang, setNamaBarang] = useState(null)
  const [kategori, setKategori] = useState(null)
  const [jenisBarang, setJenisBarang] = useState(null)
  const [defect, setDefect] = useState(null)
  const [lebar, setLebar] = useState(null)
  const [satuan, setSatuan] = useState(null)
  const [tebal, setTebal] = useState(null)
  const [panjang, setPanjang] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [brand, setBrand] = useState(null)
  const [imgUrl, setImageUrl] = useState(null)
  const [price, setPrice] = useState(null)
  const [objOne, setObjOne] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null);


  const [addItem, setAddItem] = useState(null)
  const sections = [
    { id: 1, label: 'MIXER' },
    { id: 2, label: 'EXTRUDER' },
    { id: 3, label: 'PRINTING' },
    { id: 4, label: 'LAMINATING' },
    { id: 5, label: 'PACKING' },

  ];
  const [pilihAktif, setPilihAktif] = useState([
    { value: 1, label: 'Aktif' },
    { value: 0, label: 'Non Aktif' },
  ])
  const [checkedList, setCheckedList] = useState([]);

  const onChange = (checkedValues) => {
    setCheckedList(checkedValues);
  };

  const { Option } = Select;

  // useEffect(() => {
  //   dispatch(getMe());
  // }, [dispatch]);

  useEffect(() => {
    // if (isError) {
    //   navigate("/");
    // }
    onLoad()
    getUOM()
    getKategori()
  }, []);
  const handleModal = () => {
    setOpenModal(!openModal)
  }
  const handleModalEdit = async (obj) => {
    console.log(obj);
    // return
    setOpenModalEdit(!openModalEdit)
    if (!openModalEdit) {
      // console.log(obj);
      setLoading(true)
      console.log(`product/getFormulaExtruder?m_product_id=${obj.m_product_id}`);
      await axios.get(`${API_URL}product/getFormulaExtruder?m_product_id=${obj.m_product_id}`, {

      }).then(function (res) {
        setLoading(false)
        setAddItem(null)
        if (res.data.length > 0) {
          let dt = res.data
          let nat = dt.find((o) => { return o.tipe == 'NAT' })
          nat && setAddItem({ ...addItem, nat: nat.kode_item, qtynat: nat.qty })
          let laminate = dt.find((o) => { return o.tipe == 'LAMINATE' })
          laminate && setAddItem({ ...addItem, laminate: laminate.kode_item, laminateqty: laminate.qty })
          let hot = dt.find((o) => { return o.tipe == 'HOTSTAMP' })
          hot && setAddItem({ ...addItem, hotstamp: hot.kode_item, hotstampqty: hot.qty })

        }
      })
      setImageUrl(`${API_URL}product/getImage/${obj.img}`)
      setMproductId(obj.m_product_id)
      setKodeBarang(obj.kode_barang)
      setNamaBarang(obj.nama_barang)
      setKategori(obj.kategori)
      setJenisBarang(obj.jenis_barang)
      setUomPilihan(obj.satuan)
      setTebal(obj.tebal)
      setPanjang(obj.panjang)
      setPrice(obj.price)
      setBrand(obj.brand)
      setMoldingPilih(obj.tipe_molding)
      let dtsec = []
      if (obj.work_center) {
        dtsec = obj.work_center.split(",").map(Number)
      }
      setCheckedList(dtsec)
      setIsactive(obj.isactive)
      console.log(obj, '************8');
    } else {
      clearAllStates()
    }
    setOpenModalEdit(!openModalEdit)
  }
  const onLoad = async () => {
    await axios.get(`${API_URL}product/getProducts`)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setData(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get(`${API_URL}product/getMolding`)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setMolding(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get(`${API_URL}item/getItemExtruder`)
      .then(function (response) {
        if (response.status == 200) {
          console.log('yaide', response.data);
          setItemExt(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  const getUOM = async () => {
    await axios.get(`${API_URL}item/getUOM`)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setUom(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getKategori = async () => {
    await axios.get(`${API_URL}item/getKategori`)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setListKategori(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const clearAllStates = () => {
    setMproductId(null); // Mengosongkan state yang merupakan null atau nilai default
    setKodeBarang(null);
    setNamaBarang(null);
    setKategori(null);
    setJenisBarang(null);
    setSatuan(null);
    setTebal(null);
    setPanjang(null);
    setSupplier(null);
    setBrand(null);
    setPrice(null);
    setIsactive(null);
    setSelectedFile(null)
    setCheckedList([])
  };

  const actionEditImg = () => {

    const formData = new FormData();
    formData.append('m_product_id', mProductId);
    formData.append('img1', selectedFile, selectedFile.name);
    // console.log(formData);
    // return
    setLoading(true)
    axios
      .post(`${API_URL}product/updateImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        console.log(response.data);
        if (response.status == "200") {
          clearAllStates()
          setOpenModalEdit(false)
          onLoad()
          setLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const actionAdd = () => {
    if (checkedList.toString() == "" || !kodeBarang || !namaBarang || !uomPilihan || !price) {
      alert(`Lengkapi dulu isiannya..`)
      return
    }
    const formData = new FormData();
    formData.append('kode_barang', kodeBarang);
    formData.append('nama_barang', namaBarang);
    formData.append('kategori', kategori);
    formData.append('satuan', uomPilihan);
    formData.append('tebal', tebal);
    formData.append('brand', brand);
    formData.append('price', price);
    formData.append('lebar', lebar);
    formData.append('panjang', panjang);
    formData.append('jenis_barang', jenisBarang);
    formData.append('deffect', defect);
    formData.append('moldingPilih', moldingPilih);
    formData.append('work_center', checkedList.toString());
    // if (selectedFile) {
    formData.append('img1', selectedFile, selectedFile.name);
    // }
    // console.log('papa');
    // return
    setLoading(true)

    axios
      .post(`${API_URL}product/createProduct`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        // console.log(response.data);
        if (response.status == "200") {
          clearAllStates()
          handleModal()
          onLoad()
          setLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const actionEdit = () => {
    // setLoading(true)
    if (!window.confirm('Yakin akan melakukan update data ..?')) {
      return
    }
    axios
      .post(`${API_URL}product/updateProduct`, {
        m_product_id: mProductId,
        kode_barang: kodeBarang,
        nama_barang: namaBarang,
        kategori: kategori,
        satuan: uomPilihan,
        tebal: tebal,
        brand: brand,
        price: price,
        lebar: lebar,
        jenisBarang: jenisBarang,
        moldingPilih: moldingPilih,
        panjang: panjang,
        work_center: checkedList.toString(),
        addItem: addItem,
        isactive: isActive,
      })
      .then(function (response) {
        console.log(response);
        if (response.status == 200) {
          alert(`Berhasil`)
          onLoad()
          handleModalEdit()
        }
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Mendapatkan file yang dipilih
    setSelectedFile(file);
  };


  const vLoad = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <Spin spinning={loading}>
              <div>
                {
                  (mProductId) ?
                    <Image
                      width={200} // Atur lebar gambar sesuai kebutuhan
                      src={imgUrl} // Ganti URL gambar sesuai dengan yang Anda inginkan
                      alt="Image Description"
                    />
                    :
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          name="resume"
                          onChange={handleFileChange}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fas fa-upload"></i>
                          </span>
                          <span className="file-label">
                            Choose a file…
                          </span>
                        </span>
                      </label>
                      {selectedFile && (
                        <div style={{ marginLeft: 10 }}>
                          <p>Selected File: {selectedFile.name}</p>
                          {/* <p>File URI: {URL.createObjectURL(selectedFile)}</p> */}
                        </div>
                      )}
                    </div>
                }
              </div>
              <div style={{ backgroundColor: '#dcb170', paddingTop: 10, paddingBottom: 10, paddingLeft: 8, borderRadius: 7 }}>
                {
                  (mProductId) &&
                  <div>
                    <label>Edit Gambar (Jika Diperlukan) </label>
                    <Row>
                      <Col span={12}>
                        <div className="file">
                          <label className="file-label">
                            <input
                              className="file-input"
                              type="file"
                              name="resume"
                              onChange={handleFileChange}
                            />

                            <span className="file-cta">
                              <span className="file-icon">
                                <i className="fas fa-upload"></i>
                              </span>
                              <span className="file-label">
                                Choose a file…
                              </span>
                            </span>
                          </label>
                          {selectedFile && (
                            <div style={{ marginLeft: 10 }}>
                              <p>Selected File: {selectedFile.name}</p>
                              {/* <p>File URI: {URL.createObjectURL(selectedFile)}</p> */}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <Button style={{ float: "right", marginRight: 10 }} type="primary" primary onClick={() => {
                          actionEditImg()
                        }}>Edit Gambar</Button>
                      </Col>
                    </Row>
                  </div>

                }
              </div>
              <div className="formDevide">
                <div className="formDevide">
                  <label htmlFor="">Aktivasi Produk</label>
                  <Select
                    value={isActive == 1 ? 'Aktif' : 'Non Aktif'} // Set defaultValue ke null atau pilihan default yang sesuai
                    style={{
                      width: '100%',
                    }}
                    onChange={(value) => {
                      console.log(value);
                      // if (!value) {
                      setIsactive(value);
                      // }
                    }}
                  >
                    {pilihAktif.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <Row>
                <Col span={12} style={{ paddingRight: 7 }}>
                  <div className="formDevide">
                    <label htmlFor="">Kode Barang</label>
                    <Input placeholder="" value={kodeBarang} onChange={(c) => {
                      setKodeBarang(c.target.value)
                    }} />
                  </div>
                </Col>
                <Col span={12} style={{ paddingLeft: 7 }}>
                  <div className="formDevide">
                    <label htmlFor="">Tipe Molding</label>
                    <Select
                      value={moldingPilih} // Set defaultValue ke null atau pilihan default yang sesuai
                      style={{
                        width: '100%',
                      }}
                      onChange={(value) => {
                        // console.log(value);
                        if (value) {
                          setMoldingPilih(value);
                        } else {
                          setMoldingPilih(null);
                        }
                      }}
                    >
                      {molding.map((option) => (
                        <Option key={option.m_tipe_molding_id} value={option.m_tipe_molding_id}>
                          {option.tipe_molding}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>

              <div className="formDevide">
                <label htmlFor="">Nama Barang</label>
                <Input placeholder="" value={namaBarang} onChange={(c) => {
                  setNamaBarang(c.target.value)
                }} />
              </div>
              <Row>
                <Col span={12}>
                  <div className="formDevide">
                    <label htmlFor="">Kategori</label>
                    <Select
                      value={kategori ? kategori : 'Pilih'} // Set defaultValue ke null atau pilihan default yang sesuai
                      style={{
                        width: '100%',
                      }}
                      onChange={(value) => {
                        console.log(value);
                        setKategori(value);
                      }}
                    >
                      {listKategori.map((option) => (
                        <Option key={option.kategori} value={option.kategori}>
                          {option.kategori}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <div className="formDevide">
                    <label htmlFor="">Tipe Barang</label>
                    <Input placeholder="" value={jenisBarang} onChange={(c) => {
                      setJenisBarang(c.target.value)
                    }} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="formDevide">
                    <label htmlFor="">Satuan</label>
                    <Select
                      defaultValue={uomPilihan ? uomPilihan : 'Pilih'} // Set defaultValue ke null atau pilihan default yang sesuai
                      style={{
                        width: '100%',
                      }}
                      onChange={(value) => {
                        console.log(value);
                        setUomPilihan(value);
                      }}
                    >
                      {uom.map((option) => (
                        <Option key={option.uom} value={option.uom}>
                          {option.uom}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <div className="formDevide">
                    <label htmlFor="">Tebal (mm)</label>
                    <Input placeholder="" value={tebal} onChange={(c) => {
                      setTebal(c.target.value)
                    }} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <div className="formDevide">
                        <label htmlFor="">Panjang (cm)</label>
                        <Input placeholder="" type="number" value={panjang} onChange={(c) => {
                          setPanjang(c.target.value)
                        }} />
                      </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                      <div className="formDevide">
                        <label htmlFor="">Lebar (cm)</label>
                        <Input placeholder="" type="number" value={lebar} onChange={(c) => {
                          setLebar(c.target.value)
                        }} />
                      </div>
                    </Col>
                  </Row>

                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <div className="formDevide">
                    <label htmlFor="">Brand</label>
                    <Input placeholder="" value={brand} onChange={(c) => {
                      setBrand(c.target.value)
                    }} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="formDevide">
                    <label htmlFor="">Price</label>
                    <Input placeholder="" value={price} onChange={(c) => {
                      setPrice(c.target.value)
                    }} />
                  </div>
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <div className="formDevide">
                    <label htmlFor="">Persentase Kerusakan (%)</label>
                    <Input placeholder="" value={defect} onChange={(c) => {
                      setDefect(c.target.value)
                    }} />
                  </div>
                </Col>
              </Row>

              <div className="formDevide">
                <Checkbox.Group style={{ display: 'flex', flexDirection: 'row' }} value={checkedList} onChange={onChange}>
                  {sections.map(section => (
                    <Checkbox key={section.id} value={section.id} style={{ marginRight: '5px' }}>
                      {section.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
              <Row style={{ marginBottom: 2 }}>
                <Col span={24} style={{ padding: 5, backgroundColor: '#dcb170', borderRadius: 5, color: '#ffff' }}>
                  Raw Material Penunjang Extruder
                </Col>
              </Row>
              {/* //HOTSTAMP */}
              <Row style={{ marginBottom: 5 }}>
                <Col span={12} style={{ paddingBottom: 6 }}>
                  <label>ITEM HOTSTAMP</label>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    value={addItem ? addItem.hotstamp : ''}
                    onChange={(value) => {
                      console.log(value);
                      setAddItem({ ...addItem, hotstamp: value })
                      // setKategori(value);
                    }}
                  >
                    {itemExt.filter((o) => { return o.tipe == 'HOTSTAMP' }).map((option) => (
                      <Option key={option.kode_item} value={option.kode_item}>
                        {option.nama_item}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={12} style={{ paddingLeft: 8 }}>
                  <label>Qty</label>
                  <Input type="numeric" value={addItem ? addItem.hotstampqty : ''} onChange={(v) => {
                    setAddItem({ ...addItem, hotstampqty: v.target.value })
                  }} />
                </Col>
              </Row>
              {/* NAT */}
              <Row style={{ marginBottom: 5 }}>
                <Col span={12} style={{ paddingBottom: 6 }}>
                  <label>ITEM NAT</label>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    value={addItem ? addItem.nat : ''}
                    onChange={(value) => {
                      console.log(value);
                      // setKategori(value);
                      setAddItem({ ...addItem, nat: value })
                    }}
                  >
                    {itemExt.filter((o) => { return o.tipe == 'NAT' }).map((option) => (
                      <Option key={option.kode_item} value={option.kode_item}>
                        {option.nama_item}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={12} style={{ paddingLeft: 8 }}>
                  <label>Qty</label>
                  <Input type="numeric" value={addItem ? addItem.qtynat : ''} onChange={(v) => {
                    setAddItem({ ...addItem, qtynat: v.target.value })
                  }} />
                </Col>
              </Row>
              {/* LAMINATE */}
              <Row style={{ marginBottom: 5 }}>
                <Col span={12} style={{ paddingBottom: 6 }}>
                  <label>ITEM LAMINATE</label>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    value={addItem ? addItem.laminate : ''}
                    onChange={(value) => {
                      setAddItem({ ...addItem, laminate: value })
                      // setKategori(value);
                    }}
                  >
                    {itemExt.filter((o) => { return o.tipe == 'LAMINATE' }).map((option) => (
                      <Option key={option.kode_item} value={option.kode_item}>
                        {option.nama_item}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={12} style={{ paddingLeft: 8 }}>
                  <label>Qty</label>
                  <Input type="numeric" value={addItem ? addItem.laminateqty : ''} onChange={(v) => {
                    setAddItem({ ...addItem, laminateqty: v.target.value })
                  }} />
                </Col>
              </Row>

              <div style={{ display: "flex" }}>
                <Button type="primary" primary onClick={() => {
                  if (!mProductId) {
                    console.log('aaxx');
                    actionAdd()
                  } else {
                    console.log('aa');
                    actionEdit()
                  }
                }}>Confirm Data</Button>
              </div>
            </Spin>
          </Col>
        </Row>
      </>
    )
  }
  const handleModalFormula = (obj) => {
    console.log(obj);
    setObjOne(obj)
    setOpenModalFormula(!openModalFormula)
    if (!obj) {
      onLoad()
    }
  }
  return (
    <>
      {
        (openModalFormula) &&
        <ModalFormula key={'fx'} objek={objOne}
          handleModal={handleModalFormula}
          title={"Formula/BOM Product"}
          width={900} />
      }
      {
        (openModal) &&
        <ModalForm width={800} handleModal={() => {
          handleModal()
          setAddItem(null)
        }} title={"Tambah Data Produk"} content={vLoad()} isfooter={'0'} />
      }
      {
        (openModalEdit) &&
        <ModalForm handleModal={() => {
          handleModalEdit()
          setAddItem(null)
        }} title={"Edit Data Produk"} content={vLoad()} isfooter={'0'} width={800} />
      }
      <Card title="Produk Finish Good">
        <Spin spinning={loading}>
          <div style={{ marginBottom: 10, textAlign: 'right' }}>
            <Button type="primary" onClick={() => {
              setOpenModal(true)
            }}>Tambah Produk</Button>
          </div>
          <TableComponent datas={data} onEdit={handleModalEdit} onFormulaOpen={handleModalFormula} />
        </Spin>
      </Card>
    </>
  );
};

export default Products;
