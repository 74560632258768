import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";
import TableComponent from './TableComponent.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";

export default function RawMaterial() {
    const [data, setData] = useState([])
    const [uom, setUom] = useState([])
    const [itemId, setItemId] = useState(null)
    const [namaItem, setNamaItem] = useState(null)
    const [uomPilihan, setUomPilihan] = useState(null)
    const [price, setPrice] = useState(null)
    const [aktif, setAktif] = useState(null)
    const [kodeItem, setKodeItem] = useState(null)
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tipe, setTipe] = useState(null)

    const [ketegori, setKategori] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);
    const { Option } = Select;
    useEffect(() => {
        onLoad()
        getUOM()
        getTipe()
    }, []);

    const onLoad = async () => {
        await axios.get(`${API_URL}item/getItem`)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    setData(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getUOM = async () => {
        await axios.get(`${API_URL}item/getUOM`)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    setUom(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getTipe = async () => {
        await axios.get(`${API_URL}item/getTipe`)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    setKategori(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const vModal = () => {
        return (
            <Row>
                <Col span={24}>
                    <Spin spinning={loading}>
                        <div className="formDevide">
                            <label htmlFor="">Kode Item</label>
                            <Input placeholder="" value={kodeItem} onChange={(c) => {
                                setKodeItem(c.target.value)
                            }} />
                        </div>
                        <div className="formDevide">
                            <label htmlFor="">Nama Item</label>
                            <Input placeholder="" value={namaItem} onChange={(c) => {
                                setNamaItem(c.target.value)
                            }} />
                        </div>
                        <div className="formDevide">
                            <label htmlFor="">Kategori</label>
                            <Select
                                defaultValue={tipe ? tipe : 'Pilih'} // Set defaultValue ke null atau pilihan default yang sesuai
                                style={{
                                    width: '100%',
                                }}
                                onChange={(value) => {
                                    // console.log(value);
                                    setTipe(value);
                                }}
                            >
                                {ketegori.map((option) => (
                                    <Option key={option.nama_tipe} value={option.nama_tipe}>
                                        {option.nama_tipe}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="formDevide">
                            <label htmlFor="">UOM</label>
                            {/* <Select
                            defaultValue={uom ? uom : null}
                            style={{
                                width: '100%',
                            }}
                            onChange={(c) => {
                                console.log(c);
                                setUomPilihan(c)
                            }}
                            options={uom.map(option => <Option key={option.uom} value={option.uom}>{option.uom}</Option>)}
                        /> */}
                            <Select
                                defaultValue={uomPilihan ? uomPilihan : 'Pilih'} // Set defaultValue ke null atau pilihan default yang sesuai
                                style={{
                                    width: '100%',
                                }}
                                onChange={(value) => {
                                    console.log(value);
                                    setUomPilihan(value);
                                }}
                            >
                                {uom.map((option) => (
                                    <Option key={option.uom} value={option.uom}>
                                        {option.uom}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="formDevide">
                            <label htmlFor="">Price</label>
                            <Input placeholder="" type="number" value={price} onChange={(c) => {
                                setPrice(c.target.value)
                            }} />
                        </div>
                        <div className="formDevide">
                            <label htmlFor="">Isactive</label>
                            <Select // Set defaultValue ke null atau pilihan default yang sesuai
                                style={{
                                    width: '100%',
                                }}
                                onChange={(value) => {
                                    setAktif(value.toString());
                                }}
                            >
                                <Option value="1">Aktif</Option>
                                <Option value="0">Non Aktif</Option>
                            </Select>
                        </div>
                    </Spin>
                </Col>
            </Row>
        )
    }
    const handleModal = () => {
        setModalAdd(!modalAdd)
        if (modalAdd) {
            setNamaItem(null)
            setUomPilihan(null)
            setTipe(null)
            setKodeItem(null)
            setPrice(null)
            setItemId(null)
        }
    }
    const handleModalEdit = (obj) => {
        if (!modalEdit) {
            console.log(obj);
            setKodeItem(obj.kode_item)
            setItemId(obj.m_item_id)
            setNamaItem(obj.nama_item)
            setUomPilihan(obj.uom)
            setTipe(obj.tipe)
            setPrice(obj.price)
        } else {
            setNamaItem(null)
            setUomPilihan(null)
            setTipe(null)
            setKodeItem(null)
            setPrice(null)
            setItemId(null)
        }
        setModalEdit(!modalEdit)
    }
    const actionAdd = () => {
        console.log(namaItem, price, uomPilihan);
        // return
        // setLoading(true)
        axios
            .post(`${API_URL}item/create`, {
                kode_item: kodeItem,
                nama_item: namaItem,
                uom: uomPilihan,
                qty: 1,
                tipe: tipe,
                price: price,
            })
            .then(function (response) {
                console.log(response);
                if (response.status == 200) {
                    setKodeItem(null)
                    setNamaItem(null)
                    setTipe(null)
                    setPrice(null)
                    setUomPilihan(null)
                    handleModal()
                    onLoad()
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const actionEdit = () => {
        console.log(namaItem, price, uomPilihan, '=>><<==');
        setLoading(true)
        axios
            .post(`${API_URL}item/updateItem`, {
                m_item_id: itemId,
                kode_item: kodeItem,
                nama_item: namaItem,
                uom: uomPilihan,
                qty: 1,
                tipe: tipe,
                price: price,
                isactive: aktif
            })
            .then(function (response) {
                // console.log(response);
                if (response.status == 200) {
                    onLoad()
                    handleModalEdit()
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>
            {
                (modalAdd == true) &&
                <ModalForm title={"Tambah Data Raw Material"} content={vModal()} handleModal={handleModal} onOke={actionAdd} />
            }
            {
                (modalEdit == true) &&
                <ModalForm title={"Edit Raw Materials"} content={vModal()} handleModal={handleModalEdit} onOke={actionEdit} />
            }
            <Card title="Data Raw Material" color="#f0f2f5">
                {/* <Alert message="Success Tips" type="success" showIcon closable style={{marginBottom: 10}}/> */}
                <Row>
                    <Col span={"24"}>
                        <div style={{ display: 'flex', gap: 3 }}>
                            <Button type="primary" success style={{ float: "right", marginBottom: 10 }} onClick={() => {
                                setModalAdd(true)
                            }}>Tambah Data</Button>
                            {/* <Button type="primary" danger>Terima Kedatangan</Button> */}
                        </div>
                        <TableComponent datas={data} onEdit={handleModalEdit} />
                    </Col>
                </Row>
            </Card>
        </>
    )
}
