import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";


import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import MixerTab from "./TabsComponentDetail/MixerTab.jsx";
import ExtruderTab from "./TabsComponentDetail/ExtruderTab";
import PackagingTab from "./TabsComponentDetail/PackaginTab";
import PrintingTab from "./TabsComponentDetail/PrintingTab";
import LaminatingTab from "./TabsComponentDetail/LaminatingTab";
import axios from "axios";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined,
} from '@ant-design/icons';
import "./styles.css";
import PrintComponent from '../../components/PrintComponent';

export default function InputHasil() {
    const [data, setData] = useState([])
    const [tglSPK, setTglSPK] = useState(null)
    const [nomorSpk, setNomorSPK] = useState(null)
    const [dtlFormula, setDtlFormula] = useState(null)
    const [formula, setFormula] = useState([])
    const [hasilFormula, setHasilFormula] = useState([])
    const [customer, setCustomer] = useState(null)
    const [barang, setBarang] = useState(null)
    const [qty, setQty] = useState(null)
    const [qtyDus, setQtyDus] = useState(null)
    const [qtyPerDus, setQtyPerdus] = useState(null)
    const [berat, setBerat] = useState(null)
    const [dataCustomer, setDataCustomer] = useState([])
    const [dataItem, setDataItem] = useState([])
    const [partner, setPartner] = useState([])
    const [nomorPO, setNomorPO] = useState(null)
    const [tabsContent, setTabsContent] = useState([])
    const [note, setNote] = useState(null)
    const [warna, setWarna] = useState(null)
    const [tipe, setTipe] = useState(null)
    const [lebar, setLebar] = useState(null)
    const [tebal, setTebal] = useState(null)
    const [panjang, setPanjang] = useState(null)
    const [rumusProduksi, setRumusProduksi] = useState(null)
    const { id } = useParams();
    const { section } = useParams();

    useEffect(() => {
        console.log(id, section);
        onLoad()
    }, [])
    const onLoad = async () => {
        await axios.get(`${API_URL}spk/findFormulaSec?spk_id=${id}&section=${section}`)
            .then(function (response) {
                if (response.status == 200) {
                    setData(response.data.filter((o)=>{
                        return o.qty > 0
                    }))
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const header = (obj) => {
        return (
            <Row>
                <Col span={12}>
                    <div className="formDevide">
                        <p className="formDevide">Nomor SPK</p>
                        <p className="isi"><b>{data[0].nomor_spk}</b></p>
                    </div>
                    <div className="formDevide">
                        <p className="formDevide">Nama Barang</p>
                        <p className="isi"><b>{data[0].nama_product}</b></p>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="formDevide">
                        <p className="formDevide">Section</p>
                        <p className="isi"><b>{section}</b></p>
                    </div>
                    <div style={{float:'right',paddingRight:10}}>
                        <Button type="primary" danger>Kembali</Button>
                        <Button type="primary" primary>Proses</Button>
                    </div>
                </Col>
            </Row>
        )
    }
    return (
        (data.length > 0) ?
            <>
                {header()}
                <Row style={{padding:7,backgroundColor:'#555555',color:'#ffff',borderRadius:5}}>
                    <Col span={2}>No</Col>
                    <Col span={5}>Kode Item</Col>
                    <Col span={7}>Nama Item</Col>
                    <Col span={3}>Satuan</Col>
                    <Col span={3}>Qy</Col>
                    <Col span={4}>Request</Col>
                </Row>
                <div>
                    {
                        data.map((o, index) => (
                            <Row key={index} style={{padding:7,paddingBottom:15,backgroundColor: index % 2 === 0 ?'#ffff' : '#ececec',color:'#100d33',borderRadius:0}}>
                                <Col span={2}>{index+1}</Col>
                                <Col span={5}>{o.kode_item}</Col>
                                <Col span={7}>{o.nama_item}</Col>
                                <Col span={3}>{o.satuan}</Col>
                                <Col span={3}>{o.qty}</Col>
                                <Col span={4}>
                                    <Input type="number" onChange={(e)=>{
                                        let pn = data.filter((v)=>{
                                            return v.spk_detail_formula_id !== o.spk_detail_formula_id
                                        })
                                        let dt = {... o,request:e.target.value}
                                        pn.push(dt)
                                        setData(pn)
                                    }}/>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </>
            : null
    )
}
