import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card, Input, Spin, Row, Col, Select, Tabs, Image
    , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import axios from "axios";
import ModalForm from "../../components/Modal/index.jsx";
import {
    SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
    , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
    , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
    , ExportOutlined
} from '@ant-design/icons';
import "./styles.css";
export default function UserForm() {
    const [username, setUserName] = useState(null)
    const [userId, setUserId] = useState(null)
    const [nama, setNama] = useState(null)
    const [pwd, setPwd] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        let usr = localStorage.getItem('user')
        usr = JSON.parse(usr)
        console.log(usr);

        // usr = usr.dataUser
        setUserId(usr.uuid)
        setUserName(usr.email)
        setNama(usr.name)
    }, [])

    const resetPwd = async () =>{
        console.log(username,pwd);
        axios
          .post(`${API_URL}partner/reset`, {
            username: username,
            pwd:pwd
          })
          .then(function (response) {
            console.log(response);
            if (response.status == 200) {
              alert(`Berhasil`)
            }
            setLoading(false)
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    return (
        <div>
            <Card title={"Form User Account"}>
                <Row>
                    <Col span={24}>
                        <div>
                            <label>Username</label>
                            <Input value={username} />
                        </div>
                        <div>
                            <label>Nama</label>
                            <Input value={nama} />
                        </div>
                        <div>
                            <label>Ganti Password</label>
                            <Input value={pwd} onChange={(c)=>{
                                setPwd(c.target.value)
                            }} />
                        </div>
                        <div style={{ marginTop: 20, width: 100 }}>
                            <Button style={{ width: '100%' }} type="primary" primary onClick={resetPwd}>Edit</Button>
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
