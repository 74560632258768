import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card, Input, Spin, Row, Col, Select, Tabs, Image
  , Button, Space, Alert, Dropdown, message, Tooltip
} from "antd";
import { API_URL } from '../../features/global.js'
import axios from "axios";
import ModalForm from "../../components/Modal/index.jsx";
import {
  SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
  , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
  , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileExcelOutlined
  , ExportOutlined
} from '@ant-design/icons';
import "./styles.css";
import SelectBox from "../../components/SelectBox.jsx";
import TableComponent from "./TableComponent.jsx";


export default function UserAccount() {
  const { options } = Select
  const [data, setData] = useState([])
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [obj, setObj] = useState(null)
  const extrasFilter = () => {
    return (
      <Row>
        <Col span={24}>
          <Button style={{ float: 'right' }} type="primary" primary onClick={() => {
            setModal(true)
          }}><PlusCircleOutlined /> Tambah User</Button>
        </Col>
      </Row>
    )
  }
  useEffect(() => {
    onLoad()
  }, [])
  const onLoad = async () => {
    await axios.get(`${API_URL}auth/find`)
      .then(function (response) {
        // console.log(response);
        if (response.status == 200) {
          console.log(response.data.data);
          setData(response.data.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const konfirmasi = async () => {
    if (!obj) {
      return
    }
    setLoading(true)
    axios
      .post(`${API_URL}auth/createUser`, {
        nik: obj.nik,
        nama: obj.nama,
        email: obj.username,
        password: "123",
        confPassword: "123",
        role: obj.role
      })
      .then(function (response) {
        setLoading(false)
        if (response.status == 200) {
          alert(response.data.msg)
          setModal(false)
          setObj(null)
          onLoad()
        }
      })
      .catch(function (error) {
        setLoading(false)
      });

  }
  const formInput = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <div>
              <label>User Name</label>
              <Input value={obj && obj.username} onChange={(o) => { setObj({ ...obj, username: o.target.value }) }} />
            </div>
            <div>
              <label>NIK</label>
              <Input value={obj && obj.nik} onChange={(o) => { setObj({ ...obj, nik: o.target.value }) }} />
            </div>
            <div>
              <label>Nama </label>
              <Input value={obj && obj.nama} onChange={(o) => { setObj({ ...obj, nama: o.target.value }) }} />
            </div>
            <div>
              <label>Role</label>
              <Select
                style={{ width: '100%' }}
                onChange={(v) => {
                  console.log(v);
                  setObj({ ...obj, role: v })
                }}
                options={[
                  { value: '1', label: 'Administrator' },
                  { value: '2', label: 'PPIC' },
                  { value: '3', label: 'Admin' },
                  { value: '4', label: 'Pengawas' },
                ]}
              />
            </div>
            <div>
              <label>Default Password</label>
              <Input value={"123"} readOnly />
            </div>
            <div style={{ marginTop: 10 }}>
              <Button type="primary" primary onClick={konfirmasi}>Konfirmasi</Button>
            </div>
          </Col>
        </Row>
      </>
    )
  }
  const editAction = (v) => {
    // console.log(v);
    setObj({... obj,m_user_id:v.m_user_id})
    setModalEdit(true)
  }
  
  const formEdit = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <div>
              <label>Password</label>
              <Input onChange={(v) => {
                setObj({ ...obj, password: v.target.value })
              }} />
            </div>
            <div>
              <label>Aktivasi</label>
              <Select
                style={{ width: '100%' }}
                onChange={(v) => {
                  setObj({ ...obj, isaktif: v })
                }}
                options={[
                  { value: '1', label: 'Aktif' },
                  { value: '0', label: 'Non Aktif' }
                ]}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Button type="primary" primary onClick={async ()=>{
                console.log(obj);
                // return
                setLoading(true)
                await axios.post(`${API_URL}auth/edituser`,{
                  pwd:obj.password,
                  m_user_id : obj.m_user_id,
                  isactive : obj.isaktif
                })
                .then(function(response){
                  setLoading(false)
                  if(response.status == 200){
                    alert(response.data.msg)
                    setObj(null)
                    setModalEdit(false)
                    onLoad()
                  }
                })
              }}>Konfirmasi</Button>
            </div>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <div>
      {
        (modal) &&
        <ModalForm title={"Tambah Data User"} isfooter={"N"} content={formInput()} handleModal={() => {
          setObj(null)
          setModal(false)
        }} />
      }
      {
        (modalEdit) &&
        <ModalForm title={"Edit"} isfooter={'N'} content={formEdit()} handleModal={() => {
          setModalEdit(false)
          setObj(null)
        }} />
      }
      <div>
        <Card title={"User Account"} extra={extrasFilter()}>
          <TableComponent datas={data} onEdit={editAction} />
        </Card>
      </div>
    </div>
  )
}
