import { SearchOutlined, DeleteOutlined, SettingOutlined, EditOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd';
// const data = [];
const TableComponent = ({ datas, onEdit }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  // const [data,setDatas] = useState(datas)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination) => {
    console.log(pagination);
    setPagination(pagination);
  };

  const columns = [
    // {
    //   title: (
    //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //       <SettingOutlined/>
    //     </div>
    //   ),
    //   dataIndex: 'arrowButton',
    //   key: 'arrowButton',
    //   width: '5%',
    //   render: (_, record) => (
    //     <div style={{ display: 'flex', gap: '4px' }}>
    //       <Button icon={<DeleteOutlined/>} type="primary" onClick={() => {
    //           onEdit(record)
    //         }}></Button>
    //       {/* <Button icon={<DeleteOutlined style={{color:'#ffff'}}/>} style={{backgroundColor:'#ff4d4f'}}></Button> */}

    //     </div>
    //   ),
    // },
    {
      title: 'Customer',
      dataIndex: 'nama_partner',
      key: 'nama_partner',
      ...getColumnSearchProps('nama_partner'),
    },
    {
      title: 'Nomor SO',
      dataIndex: 'nomor_po',
      key: 'nomor_po',
      ...getColumnSearchProps('nomor_po'),
    },
    // {
    //   title: 'Tgl Ambil',
    //   dataIndex: 'crt',
    //   key: 'crt',
    //   ...getColumnSearchProps('crt'),
    // },
    {
      title: 'Produk',
      dataIndex: 'nama_product',
      key: 'nama_product',
      ...getColumnSearchProps('nama_product'),
    },
    {
      title: 'Warna',
      dataIndex: 'warna_mixing',
      key: 'warna_mixing',
      ...getColumnSearchProps('warna_mixing'),
    },
    {
      title: 'Qty (Dus)',
      dataIndex: 'dus',
      key: 'dus',
      ...getColumnSearchProps('dus'),
    },
    {
      title: 'Qty Order (lbr)',
      dataIndex: 'qty_produksi',
      key: 'qty_produksi',
      ...getColumnSearchProps('qty_produksi'),
    },
    {
      title: 'Qty Ambil',
      dataIndex: 'qty_keluar',
      key: 'qty_keluar',
      ...getColumnSearchProps('qty_keluar'),
    },
    {
      title: 'Sisa (lbr)',
      dataIndex: 'sisa',
      key: 'sisa',
      ...getColumnSearchProps('sisa'),
    },
  ];
  const getRowClassName = (record, index) => {
    // Jika indeks genap, atur kelas CSS 'even-row', jika tidak, atur 'odd-row'
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };
  return (
    <>
      <Table
        columns={columns}
        rowKey="m_item_id"
        dataSource={datas}
        pagination={pagination}
        rowClassName={getRowClassName}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableComponent;