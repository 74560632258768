import React, { useState } from 'react';
import { Alert, Switch, Space } from 'antd';


function AlertBox({ handleClose,msg, tipe }) {
    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Alert message={msg} showIcon type={tipe} closable afterClose={handleClose} />
        </Space>
    )
}

export default AlertBox