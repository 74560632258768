import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Button, Modal, Space,Card, Row,Col } from 'antd';
import { Footer } from 'antd/es/layout/layout';

const ModalForm = ({handleModal,title,content,onOke,isfooter,width}) => {
    return (
      <>
        {
          (isfooter) ?
          <Modal
            title={title}
            open={true}
            style={{ top: 20 }}
            onOk={onOke}
            onCancel={handleModal}
            okText="Konfirmasi"
            footer={null}
            width={width ? width : 500}
          >
            {content}
        </Modal>
        :
        <Modal
              title={title}
              open={true}
              style={{ top: 20 }}
              onOk={onOke}
              onCancel={handleModal}
              width={width ? width : 500}
              okText="Konfirmasi"
            >
            {content}
        </Modal>
        }
        
      </>
    );
  };

  export default ModalForm