import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import {
  Layout,
  Menu,
} from 'antd';
import {
  DashboardOutlined,
  DeliveredProcedureOutlined,
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

const Sidebar = ({ }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [menul, setMenus] = useState([])
  // useEffect(()=>{
  //     if(user){
  //       console.log(user);
  //       setMenus(user.menu ? user.menu : [])
  //     }
  // },[user])
  useEffect(() => {
    let lkal = localStorage.getItem(`dataLogin`)
    if (lkal) {
      console.log('sidebar........');
      lkal = JSON.parse(lkal)
      setMenus(lkal.menu.length > 0 ? lkal.menu : [])
    } else {
      console.log('gaada sidebar........');
    }
  }, [])

  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const items = menul ? menul : []
  const renderIcon = (v) => {
    switch (v) {
      case "Dashboard":
        return <DashboardOutlined />
      case "Transaksi":
        return <DeliveredProcedureOutlined />
      default:
        return <FileOutlined />;
    }
  }
  return (
    <div>
      <Layout style={{ height: '100%' }}>
        {
          (menul) &&
          <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div className="demo-logo-vertical" />
            <div style={{ marginTop: 100 }}>
              <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" icon={<UserOutlined />}>
                {items.map((item,index) => {
                  if (item.children) {
                    return (
                      <Menu.SubMenu key={index} icon={renderIcon(item.label)} title={item.label}>
                        {item.children.map((child) => (
                          <Menu.Item key={child.key}>
                            <NavLink to={`${child.key}`}>{child.label}</NavLink>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    );
                  } else {
                    return (
                      <Menu.Item key={item.key} icon={renderIcon(item.label)}>
                        <NavLink to={`${item.key}`}>{item.label}</NavLink>
                      </Menu.Item>
                    );
                  }
                })}
              </Menu>
            </div>
          </Sider>
        }
      </Layout>
    </div>
  );
};

export default Sidebar;
