import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";
import ModalForm from '../../../components/Modal/index.jsx';


export default function ExtruderTab({ keterangan, formulas, additional, resetFormula
  , panjang, qty_produksi, line,masterFormula }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [objFormula, setObjFormlua] = useState(null)

  const [rm, setRm] = useState([])
  const [openMod, setOpenMod] = useState(false)
  const [objMod, setObjMod] = useState(null)
  const [pergantian, setPergantian] = useState(null)

  useEffect(() => {
    let px = formulas.filter(item => item.qty > 0);
    let br = px.map((o) => {
      let qtyp = o.qty
      return o
      // return o = { ...o, qty: qtyp * parseFloat(panjang / 100) * parseFloat(qty_produksi) }
    })
    setDtlFormula(br)
    setMasterFormula(br,'PACKING')
    axios
      .get(`${API_URL}item/getItem?tipe=Bahan Baku Packaging`, {})
      .then(function (response) {
        if (response.status == 200) {
          // console.log(response.data);
          setRm(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const setMasterFormula = (cb,sec) =>{
    let awal = masterFormula.filter((o)=>{
      return o.sections !== sec
    })
    cb.map(item => {
      awal.push(item)
    });
    resetFormula(awal)
  }
  const resetMod = (obj) => {
    setOpenMod(false)
    setObjMod(null)
    setPergantian(null)
  }
  const combo = () => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <p>Nama Item</p>
            <b>{objMod && objMod.nama_item}</b>
          </Col>
          <Col span={12}>
            <p>Qty</p>
            <b>{objMod && objMod.qty}</b>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>Komponen Pengganti</label>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Pilih Item"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(v) => {
                // setCustomer(v)
                let rmobj = rm.find((o) => {
                  return o.m_item_id == v
                })
                setPergantian(rmobj)
              }}
            >
              {rm.map(item => (
                <Option key={item.m_item_id} value={item.m_item_id}>
                  {item.nama_item}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col span={12} style={{ padding: 1 }}>
            <Button type='primary' style={{ width: '100%' }} danger onClick={() => {
              resetMod()
            }}>Batal</Button>
          </Col>
          <Col span={12} style={{ padding: 1 }}>
            <Button type='primary' style={{ width: '100%' }} primary onClick={() => {
              console.log(pergantian);

              let pc = dtlFormula.map((o) => {
                if (o.formula_detail_id == objMod.key_id) {
                  console.log(objMod.key_id);
                  return { ...o, kode_item: pergantian.kode_item, nama_item: pergantian.nama_item }
                }
                return o
              })
              // console.log(pc);
              setDtlFormula(pc)
              resetFormula(pc)
              setMasterFormula(pc,'PACKING')
              resetMod()
            }}>Pilih</Button>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <div>
      {
        (openMod) &&
        <ModalForm content={combo()} isfooter={'N'} handleModal={resetMod} title={"Edit Komponen RM"} />
      }
      <Row style={{ marginBottom: 10 }}>
        <Col span={12}>
          <p>Nama Formula</p>
          <p><b>{formulas[0].nama_formula}</b></p>
        </Col>
        <Col span={12}>
          <Input placeholder='Line Packaging' onChange={(v) => {
            line(v.target.value)
          }} />
        </Col>
      </Row>
      <Row style={{ backgroundColor: '#edf5fd', padding: 8, marginBottom: 1 }}>
        <Col span={3}>
          {/* {additional.nama_produk} */}
        </Col>
        <Col span={12}>
          {additional.nama_produk}
        </Col>
        <Col span={3}>
          {additional.satuan}
        </Col>
        <Col span={3}>
          -
        </Col>
        <Col span={3}>
          {additional.qty}
        </Col>
      </Row>
      {
        dtlFormula.map((o, index) => {
          return (
            <Row key={index} style={{ backgroundColor: index % 2 ? '#ffff' : '#fafafa', padding: 8, marginBottom: 1 }}>
              <Col span={3}>
                {o.kode_item}
              </Col>
              <Col span={12}>
                <Button onClick={() => {
                  console.log(o.kode_item, o.formula_detail_id);
                  setObjMod({
                    key_id: o.formula_detail_id,
                    nama_item: o.nama_item,
                    qty: o.qty
                  })
                  setOpenMod(true)
                }}>{o.nama_item}</Button>
              </Col>
              <Col span={3}>
                {o.satuan}
              </Col>
              <Col span={3}>
                {o.qty_std}
              </Col>
              <Col span={3}>
                {Math.ceil(o.qty)}
              </Col>
            </Row>
          )
        })
      }
    </div>
  )
}
