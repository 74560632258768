import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";


export default function ExtruderTab({ keterangan, formulas, additional, resetFormula, masterFormula
  , panjang, qty_produksi, line }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [objFormula, setObjFormlua] = useState(null)

  const setMasterFormula = (cb, sec) => {
    let awal = masterFormula.filter((o) => {
      return o.sections !== sec
    })
    cb.map(item => {
      awal.push(item)
    });
    resetFormula(awal)
  }
  useEffect(() => {
    let px = formulas.filter(item => item.qty > 0);
    let br = px.map((o) => {
      let qtyp = o.qty
      // return o = { ...o, qty: qtyp * parseFloat(panjang / 100) * parseFloat(qty_produksi) }
      return o = { ...o, qty: qtyp }
    })
    setDtlFormula(br)
    setMasterFormula(br, 'LAMINATNG')
  }, [])
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col span={17}>
          <p>Nama Formulas</p>
          <p><b>{formulas[0].nama_formula}</b></p>
        </Col>
        <Col span={7}>
          <Input placeholder='Line Offline' onChange={(v) => {
            line(v.target.value)
          }} />
        </Col>
      </Row>
      {/* <Row style={{ backgroundColor: '#edf5fd', padding: 8, marginBottom: 1 }}>
        <Col span={3}>
          00000
        </Col>
        <Col span={15}>
          {additional.nama_produk}
        </Col>
        <Col span={3}>
          {additional.satuan}
        </Col>
        <Col span={3}>
          {additional.qty}
        </Col>
      </Row> */}
      {
        dtlFormula.map((o, index) => {
          return (
            <Row key={index} style={{ backgroundColor: index % 2 ? '#ffff' : '#fafafa', padding: 8, marginBottom: 1 }}>
              <Col span={3}>
                {o.kode_item}
              </Col>
              <Col span={15}>
                {o.nama_item}
              </Col>
              <Col span={3}>
                {o.satuan}
              </Col>
              <Col span={3}>
                {o.qty}
              </Col>
            </Row>
          )
        })
      }
    </div>
  )
}
