import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";
import TableComponent from './TableComponent.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";


export default function MasterMolding() {
    const [data, setData] = useState()
    const [tipeMolding, setTipeMolding] = useState(null)
    const [mesin, setMesin] = useState(null)
    const [moldingId, setMoldingId] = useState(null)
    const [target, setTarget] = useState(null)
    const [modalAdd, setModalAdd] = useState(false)
    useEffect(() => {
        onLoad()
    }, [])
    const onEdit = (v) => {
        setMoldingId(v.m_tipe_molding_id)
        setMesin(v.mesin)
        setTipeMolding(v.tipe_molding)
        setTarget(v.target)
        setModalAdd(true)
    }
    const actionMolding = async () => {
        let obj = {
            moldingId, tipeMolding, mesin, target
        }
        await axios.post(`${API_URL}product/actionMolding`, {
            ob: obj
        })
            .then(function (response) {
                // console.log(response);
                if (response.status == 200) {
                    alert(response.data.msg);
                    onLoad()
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        console.log(obj);
    }
    const onLoad = async () => {
        await axios.get(`${API_URL}product/getMolding`)
            .then(function (response) {
                console.log(response);
                if (response.status == 200) {
                    setModalAdd(false)
                    setMesin(null)
                    setMoldingId(null)
                    setTarget(null)
                    setTipeMolding(null)
                    console.log(response.data);
                    setData(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const v_Molding = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <label>Tipe Molding</label>
                        <Input type='text' value={tipeMolding} onChange={(c) => {
                            setTipeMolding(c.target.value)
                        }} />
                    </Col>
                    <Col span={24}>
                        <label>Line Mesin</label>
                        <Input type='text' value={mesin} onChange={(c) => {
                            setMesin(c.target.value)
                        }} />
                    </Col>
                    <Col span={24}>
                        <label>Target</label>
                        <Input type='text' value={target} onChange={(c) => {
                            setTarget(c.target.value)
                        }} />
                    </Col>
                    <Col span={24}>
                        <Button type='primary' prumary style={{ marginTop: 10 }} onClick={actionMolding}> Konfirmasi </Button>
                    </Col>
                </Row>
            </>
        )
    }
    return (
        <>
            {
                (modalAdd) &&
                <>
                    <ModalForm content={v_Molding()} title={"Tambah Data"} handleModal={() => {
                        setModalAdd(false)
                        setMesin(null)
                        setMoldingId(null)
                        setTarget(null)
                        setTipeMolding(null)
                    }} isfooter={'N'} />
                </>
            }
            <Card title="Data Kategori" color="#f0f2f5">
                <Button type="primary" style={{ float: 'right', marginBottom: 10 }} danger onClick={() => {
                    setModalAdd(true)
                }}>Tambah</Button>
                <TableComponent datas={data} onEdit={onEdit} />
            </Card>
        </>
    )
}
