import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProductList from "../../components/ProductList";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert, Tabs
} from "antd";

import TableComponentModal from "../Formula/TableComponentModal";
import ModalForm from "../../components/Modal";
import axios from "axios";
import { API_URL } from '../../features/global.js'
import MixerTab from "./TabsComponent/MixerTab";
import ExtruderTab from "./TabsComponent/ExtruderTab";
import PackagingTab from "./TabsComponent/PackaginTab";
import PrintingTab from "./TabsComponent/PrintingTab";
import LaminatingTab from "./TabsComponent/LaminatingTab";

export default function ModalFormula({ handleModal, title, objek, width }) {
  const { Option } = Select;
  const [objFormula, setObjFormlua] = useState(null)
  const [formula, setFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [pilihan, setPilihan] = useState(null)
  const [loading, setLoading] = useState(false)

  const [mxFormula, setMxFormula] = useState(null)
  const [extFormula, setExtFormula] = useState(null)
  const [lmnFormula, setLmnFormula] = useState(null)
  const [printFormula, setPrintFormula] = useState(null)
  const [pkgFormula, setkgFormula] = useState(null)

  const [mixer, setMixer] = useState(null)
  const [extruder, setExtruder] = useState(null)
  const [printing, setPrinting] = useState(null)
  const [laminating, setLaminating] = useState(null)
  const [packing, setPacking] = useState(null)


  const [tabsContent, setTabsContent] = useState([])

  useEffect(() => {
    console.log(objek, 'loh...');
    setTabsContent(objek.work_center ? objek.work_center.split(",").map(Number).sort((a, b) => a - b) : [])
    onLoad()
  }, [])
  const onLoad = async () => {
    setLoading(true)
    await axios.get(`${API_URL}formula/find`)
      .then(function (response) {
        if (response.status == 200) {
          setFormula(response.data)
          setLoading(false)
          // jika udah ada
          if (objek.m_formula_id) {
            console.log(objek);
            setMxFormula(objek.m_formula_id)
            setExtFormula(objek.formula_extruder)
            setLmnFormula(objek.formula_laminating)
            setPrintFormula(objek.formula_printing)
            setkgFormula(objek.formula_packaging)
            setMixer(objek.is_wip_mixer)
            setExtruder(objek.is_wip_extruder)
            setPrinting(objek.is_wip_printing)
            setLaminating(objek.is_wip_laminating)
            // setPacking(objek.is_wip_laminating)
          }

        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });
  }

  const findOne = async (v) => {
    await axios.post(`${API_URL}formula/findOne`, {
      formula_id: v
    })
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          setDtlFormula(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const v_formula = () => {
    return (
      <>
        <Spin spinning={loading}>
          {
            (!loading) &&
            <div className="formDevide">
              <>
                <Row style={{ backgroundColor: '#ededed', padding: 10, borderRadius: 7 }}>
                  <Col span={12}>
                    <Row>
                      <Col span={8}>
                        <div className="formDevide">
                          <label style={{ fontSize: 10 }}><b>Kode Barang</b></label><br />
                          <label>{objek.kode_barang}</label>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="formDevide">
                          <label style={{ fontSize: 10 }}><b>Nama Barang</b></label><br />
                          <label>{objek.nama_barang}</label>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="formDevide">
                          <label style={{ fontSize: 10 }}><b>Kategori</b></label><br />
                          <label>{objek.kategori}</label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Button style={{ float: "right" }} type="primary" danger onClick={async () => {
                          console.log(objFormula, '*****');
                          if (objFormula) {
                            console.log(mxFormula, extFormula, lmnFormula, pkgFormula, printFormula);
                            setLoading(true)
                            await axios.post(`${API_URL}formula/updateProductFormula`, {
                              formula_id: objFormula.formula_id,
                              m_product_id: objek.m_product_id
                            })
                              .then(function (response) {
                                console.log(response);
                                if (response.status == 200) {
                                  setLoading(false)
                                  handleModal(null)
                                }
                              })
                              .catch(function (error) {
                                console.log(error);
                              });
                          } else {
                            await axios.post(`${API_URL}formula/updateProductFormula`, {
                              m_product_id: objek.m_product_id,
                              mxFormula: mxFormula,
                              extFormula: extFormula,
                              lmnFormula: lmnFormula,
                              pkgFormula: pkgFormula,
                              printFormula: printFormula,
                              mixer: mixer,
                              extruder: extruder,
                              printing: printing,
                              laminating: laminating,
                              packing: packing
                            })
                              .then(function (response) {
                                console.log(response);
                                if (response.status == 200) {
                                  setLoading(false)
                                  handleModal(null)
                                }
                              })
                              .catch(function (error) {
                                console.log(error);
                              });
                          }

                        }}>{'Konfirmasi Formula'}</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
              {/* dari sini */}
              <div style={{ marginTop: 10 }}>
                <Tabs type="card">
                  {
                    tabsContent.map((item, index) => (
                      <Tabs.TabPane tab={cekTitleTab(item)} key={String(item)}>
                        <div style={{ paddingLeft: 10 }}>
                          {tabSwitch(item)}
                        </div>
                      </Tabs.TabPane>
                    ))
                  }
                </Tabs>
              </div>
              {/* sampe sini */}
            </div>
          }
        </Spin>
      </>
    )
  }
  const tabSwitch = (id) => {

    if (id == 1) {
      return <MixerTab setting={setMixer} wip={mixer} keterangan={"MIXING"} formulas={formula} id={mxFormula} setId={setMxFormula} />
    } else if (id == 2) {
      return <ExtruderTab setting={setExtruder} wip={extruder} keterangan={"EXTRUDER"} formulas={formula} id={extFormula} setId={setExtFormula} />
    } else if (id == 3) {
      return <PrintingTab setting={setPrinting} wip={printing} keterangan={"PRINTING"} formulas={formula} id={printFormula} setId={setPrintFormula} />
    } else if (id == 4) {
      return <LaminatingTab setting={setLaminating} wip={laminating} keterangan={"LAMINATING"} formulas={formula} id={lmnFormula} setId={setLmnFormula} />
    } else if (id == 5) {
      return <PackagingTab setting={setPacking} wip={packing} keterangan={"PACKAGING"} formulas={formula} id={pkgFormula} setId={setkgFormula} />
    }
  }
  const cekTitleTab = (id) => {
    if (id == 1) {
      return "Mixer"
    } else if (id == 2) {
      return "Extruder"
    } else if (id == 3) {
      return "Printing"
    } else if (id == 4) {
      return "Laminating"
    } else if (id == 5) {
      return "Packaging"
    }
  }
  return (
    <>
      <Spin spinning={loading}>
        <ModalForm width={width ? width : 1000} isfooter={"N"}
          title={title}
          content={v_formula()}
          handleModal={handleModal}
        />
      </Spin>
    </>
  )
}
