import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Products from "./pages/Products/Products";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/Products/AddProduct";
import EditProduct from "./pages/EditProduct";
import RawMaterial from "./pages/RawMaterial";
import Kategori from "./pages/Kategori";
import Formula from "./pages/Formula";
import FormulaAdd from "./pages/Formula/formulaAdd";
import FormulaEdit from "./pages/Formula/formulaEdit";
import Partner from "./pages/Partner";
import BarangMasuk from "./pages/BarangMasuk";
import SpkForm from "./pages/SPK";
import AddSPK from "./pages/SPK/AddSPK";
import DetailSPK from "./pages/SPK/DetailSPK";
import InputHasil from "./pages/SPK/InputHasil";
import LaporanHasilSPK from "./pages/Laporan/HasilSPK";
import BarangKeluar from "./pages/BarangKeluar";
import DataKeluar from "./pages/BarangKeluar/data";
import SalesOrder from "./pages/Laporan/SalesOrder";
import Layout from "./pages/Layout";
import Reject from "./pages/Laporan/Reject";
import StockRm from "./pages/Laporan/StockRm";
import UserAccount from "./pages/Account";
import UserForm from "./pages/Account/userform";
import SalesOut from "./pages/SalesOut";
import MasterMolding from "./pages/Molding";

function App() {
  const rendering = (path) => {
    switch (path) {
      case "users":
        return <Users />;
      case "kategori":
        return <Kategori />;
      case "rm":
        return <RawMaterial />;
      case "product":
        return <Products />
      case "formula":
        return <Formula />
      case "formula_add":
        return <FormulaAdd />
      case "formula_edit":
        return <FormulaEdit />
      case "partner":
        return <Partner />
      case "barangmasuk":
        return <BarangMasuk />
      case "spk":
        return <SpkForm />
      case "addspk":
        return <AddSPK />
      case "detailspk":
        return <DetailSPK />
      case "barangkeluar":
        return <DataKeluar />
      case "barangkeluar_add":
        return <BarangKeluar />
      case "inputhasilspk":
        return <InputHasil />
      case "hasilspk":
        return <LaporanHasilSPK />
      case "salesorder":
        return <SalesOrder />
      case "reject":
        return <Reject />
      case "stockrm":
        return <StockRm />
      case "account":
        return <UserAccount />
      case "user":
        return <UserForm />
      case "salesout":
        return <SalesOut />
      case "molding":
        return <MasterMolding />
      default:
        return (
          <p>NO PAGE</p>
        )
    }
  }
  return (
    <div>
      <HashRouter>
        {/* <Layout> */}
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/woi" element={<Layout children={rendering('formula')} />} /> */}
          <Route path="/dashboard" element={<Layout children={<Dashboard />} />} />
          <Route path="/product" element={<Layout children={rendering('product')} />} />
          <Route path="/rm" element={<Layout children={rendering('rm')} />} />
          <Route path="/kategori" element={<Layout children={rendering('kategori')} />} />
          <Route path="/formula" element={<Layout children={rendering('formula')} />} />
          <Route path="/formula/add" element={<Layout children={rendering('formula_add')} />} />
          <Route path="/formula/edit/:id" element={<Layout children={rendering('formula_edit')} />} />
          <Route path="/partner" element={<Layout children={rendering('partner')} />} />
          <Route path="/barangmasuk" element={<Layout children={rendering('barangmasuk')} />} />
          <Route path="/barangkeluar" element={<Layout children={rendering('barangkeluar')} />} />
          <Route path="/barangkeluar/add" element={<Layout children={rendering('barangkeluar_add')} />} />
          <Route path="/addspk" element={<Layout children={rendering('addspk')} />} />
          <Route path="/spk" element={<Layout children={rendering('spk')} />} />
          <Route path="/salesorder" element={<Layout children={rendering('salesorder')} />} />
          <Route path="/reject" element={<Layout children={rendering('reject')} />} />
          <Route path="/stockrm" element={<Layout children={rendering('stockrm')} />} />
          <Route path="/account" element={<Layout children={rendering('account')} />} />
          <Route path="/salesout" element={<Layout children={rendering('salesout')} />} />
          <Route path="/spk/detailspk/:id" element={<Layout children={rendering('detailspk')} />} />
          <Route path="/spk/inputhasilspk/:id/:section" element={<Layout children={rendering('inputhasilspk')} />} />
          <Route path="/hasilspk" element={<Layout children={rendering('hasilspk')} />} />
          <Route path="/molding" element={<Layout children={rendering('molding')} />} />
          <Route path="/user" element={<Layout children={rendering('user')} />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
