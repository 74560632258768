import { SearchOutlined,DeleteOutlined 
  ,SettingOutlined,EditOutlined,MenuUnfoldOutlined} from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd';
const data = [];
const TableComponent = ({datas,onEdit,onFormulaOpen}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
    });
    const searchInput = useRef(null);
  
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
  
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button type="link" size="small" onClick={() => close()}>
              Close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  
    const handleTableChange = (pagination) => {
      console.log(pagination);
      setPagination(pagination);
    };
  
    const columns = [   
      {
        title: 'Kode Barang',
        dataIndex: 'kode_barang',
        key: 'kode_barang',
        ...getColumnSearchProps('kode_barang'),
      },
      {
        title: 'Nama Barang',
        dataIndex: 'nama_barang',
        key: 'nama_barang',
        ...getColumnSearchProps('nama_barang'),
      },
      {
        title: 'Kategori',
        dataIndex: 'kategori',
        key: 'kategori',
        ...getColumnSearchProps('kategori'),
      },
      // {
      //   title: 'Kategori',
      //   dataIndex: 'kategori',
      //   key: 'kategori',
      //   ...getColumnSearchProps('kategori'),
      // },
      {
        title: 'Satuan',
        dataIndex: 'satuan',
        key: 'satuan',
        ...getColumnSearchProps('satuan'),
      },
      {
        title: 'Tebal',
        dataIndex: 'tebal',
        key: 'tebal',
        ...getColumnSearchProps('tebal'),
      },
      {
        title: 'Panjang',
        dataIndex: 'panjang',
        key: 'panjang',
        ...getColumnSearchProps('panjang'),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        ...getColumnSearchProps('price'),
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SettingOutlined/>
          </div>
        ),
        dataIndex: 'arrowButton',
        key: 'arrowButton',
        width: '5%',
        render: (_, record) => (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Button icon={<EditOutlined />} type="primary" onClick={() => {
                onEdit(record)
              }}></Button>
            {/* <Button icon={<DeleteOutlined style={{color:'#ffff'}}/>} style={{backgroundColor:'#ff4d4f'}}></Button> */}
            <Button icon={<MenuUnfoldOutlined style={{color:'#ffff'}}/>} onClick={()=>{
              onFormulaOpen(record)
            }} style={{backgroundColor: record.m_formula_id ? '#5dc258' : '#e8e8e8'}}/>
          </div>
        ),
      },
    ];
  
    return (
      <Table
        columns={columns}
        dataSource={datas}
        rowKey="m_product_id" 
        pagination={pagination}
        onChange={handleTableChange}
      />
    );
};
export default TableComponent;