import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import { Card, Input, Spin, Row, Col, Select
    , Button,Space,Alert } from "antd";
import TableComponent from './TableComponent.jsx'
import TableComponentModal from './TableComponentModal.jsx'
import { API_URL } from '../../features/global.js'
import ModalForm from "../../components/Modal";
import axios from "axios";
import "./styles.css";

export default function Formula() {
  const [data,setData]  = useState([])
  const [dataOne,setDataOne]  = useState([])
  const [loading,setLoading] = useState(false)
  const [modalDetail,setModalDetai] = useState(false)
  const [modalEdit,setModalEdit] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    onLoad()
  },[]);
  const onLoad = async () =>{
    await axios.get(`${API_URL}formula/find`)
        .then(function (response) {
            if (response.status == 200) {
                console.log(response.data);
                setData(response.data)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
  }

  const handleModal = () =>{
    setModalDetai(false)
  }
  
  const vDataOne = () =>{
    let totalAdukan = 0
    if(dataOne.length > 0){
        totalAdukan = dataOne.reduce((total, item) => total + item.qty_adukan, 0);
    }
    return (
        (dataOne.length > 0) ?
        <>
            <div style={{display:'flex'}}>
                <div style={{width:'50%'}}>
                    <p className="labelKecil"><b>Masa Berlaku :</b></p>
                    <p>{dataOne[0].mulai}</p>
                </div>
                <div style={{width:'50%'}}>
                    <p className="labelKecil"><b>Masa Berlaku :</b></p>
                    <p>{dataOne[0].mulai}</p>
                </div>
            </div>
            <div style={{display:'flex',marginTop:10}}>
                <div style={{width:'50%'}}>
                    <p className="labelKecil"><b>Jumlah Adukan</b></p>
                    <p>{totalAdukan}</p>
                </div>
            </div>
            <div>
                <TableComponentModal datas={dataOne}/>
            </div>
        </>
        : null
    )
  }
  const findOne = async (obj) =>{
    await axios.post(`${API_URL}formula/findOne`,{
        formula_id : obj.formula_id
    })
        .then(function (response) {
            if (response.status == 200) {
                console.log(response.data);
                setDataOne(response.data)
                setModalDetai(true)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
  }
  const editFormula = (obj) =>{
    setModalEdit(true)
    navigate(`./edit/${obj.formula_id}`)
  }
  return (
    <>
        {
            (modalDetail) &&
            <ModalForm isfooter={'N'} handleModal={handleModal} content={vDataOne()} title={dataOne.length > 0 ? dataOne[0].nama_formula : ''}/>
        }
        <Card title="Data Formula" color="#f0f2f5">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
            <Button type="primary" danger onClick={()=>{
                navigate('/formula/add')
            }}>
                Tambah Formula
            </Button>
            </div>
        </div>
            {/* <Button type="primary" danger onClick={handleModal}>Tambah Kategori</Button> */}
            <TableComponent datas={data} ondetail={findOne} onEdit={editFormula}/>
        </Card>
    </>
  )
}
