import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";


export default function ExtruderTab({ keterangan, formulas, additional,resetFormula, 
  panjang, qty_produksi,line,masterFormula }) {
  const { Option } = Select;
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState([])
  const [objFormula, setObjFormlua] = useState(null)

  const setMasterFormula = (cb,sec) =>{
    let awal = masterFormula.filter((o)=>{
      return o.sections !== sec
    })
    cb.map(item => {
      awal.push(item)
    });
    console.log(`*** RESET PRINTING ***`);
    resetFormula(awal)
  }
  function angka(angka) {
    // Jika mengandung koma, gunakan toFixed(2)
    if (angka.toString().includes('.')) {
      return parseFloat(angka).toFixed(2);
    } else {
      // Jika tidak mengandung koma, biarkan saja
      return angka.toString();
    }
  }
  useEffect(() => {
    let px = formulas.filter(item => item.qty > 0);
    let br = px.map((o) => {
      let qtyp = o.qty
      return o = { ...o, qty: qtyp * parseFloat(panjang / 100) * parseFloat(qty_produksi),qty_std:qtyp }
    })
    setDtlFormula(br)
    setMasterFormula(br,'PRINTING')
  }, [])
  return (
    <div>
      <Row style={{marginBottom:10}}>
        <Col span={12}>
          <p>Nama Formula</p>
          <p><b>{formulas[0].nama_formula}</b></p>
        </Col>
        <Col span={12}>
          <Input placeholder='Line Printing' onChange={(v) => {
            line(v.target.value)
          }} />
        </Col>
      </Row>
      <Row style={{ backgroundColor: '#edf5fd', padding: 8, marginBottom: 1 }}>
        <Col span={3}>
          {/* 00000 */}
        </Col>
        <Col span={12}>
          {additional.nama_produk}
        </Col>
        <Col span={3}>
          {additional.satuan}
        </Col>
        <Col span={3}>
          -
        </Col>
        <Col span={3}>
          {additional.qty}
        </Col>
      </Row>
      {
        dtlFormula.map((o,index) => {
          return (
            <Row key={index} style={{ backgroundColor: index % 2 ? '#ffff': '#fafafa', padding: 8,marginBottom:1 }}>
              <Col span={3}>
                {o.kode_item}
              </Col>
              <Col span={12}>
                {o.nama_item}
              </Col>
              <Col span={3}>
                {o.satuan}
              </Col>
              <Col span={3}>
                {o.qty_std}
              </Col>
              <Col span={3}>
                {angka(o.qty)}
              </Col>
            </Row>
          )
        })
      }
    </div>
  ) 
}
