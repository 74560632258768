import React, { useState, useEffect } from 'react'
import {
  Card, Input, Spin, Row, Col, Select
  , Button, Space, Alert
} from "antd";
import {
  SearchOutlined, DeleteOutlined, UserOutlined, PlusCircleOutlined
  , SettingOutlined, EditOutlined, MenuUnfoldOutlined, CheckOutlined
  , RollbackOutlined, PrinterOutlined, DeliveredProcedureOutlined, FileAddOutlined
} from '@ant-design/icons';
import axios from "axios";
import { API_URL } from '../../../features/global.js'
import TableComponentModal from "../../Formula/TableComponentModal";
import { Link, useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea.js';


export default function MixerTab({ keterangan, formulas, id, setId, warna, note }) {
  const { Option } = Select;
  const navigate = useNavigate();
  const [pilihan, setPilihan] = useState(null)
  const [dataFormula, setDataFormula] = useState([])
  const [dtlFormula, setDtlFormula] = useState(formulas)
  const [objFormula, setObjFormlua] = useState(null)
  const [notes, setNotes] = useState(note)



  useEffect(() => {
  }, [])
  return (
    <div>
      <>
        <Row style={{ marginTop: 20, paddingLeft: 7, borderRadius: 5, marginBottom: 10, }}>
          <Col span={6}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Kode Formula</b></label><br />
              <label htmlFor="">{formulas.length > 0 ? formulas[0].kode_formula : ''}</label>
            </div>
          </Col>
          <Col span={6}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Nama Formula</b></label><br />
              <label htmlFor="">{formulas.length > 0 ? formulas[0].nama_formula : ''}</label>
            </div>
          </Col>
          <Col span={6}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Line</b></label><br />
              <label htmlFor="">{formulas.length > 0 ? keterangan : ''}</label>
            </div>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Row>
              <Col span={24}>
                <Button style={{ width: 90 }} type="primary" onClick={async () => {
                  window.open(`${API_URL}spk/cetak?spk_id=${id}&section=MIXING`, '_blank');
                }}>
                  <PrinterOutlined /> Cetak
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: -15, paddingLeft: 7, borderRadius: 5, marginBottom: 10, }}>
          <Col span={12}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Warna Mixing</b></label><br />
              <label htmlFor="">{warna ? warna : ''}</label>
            </div>
          </Col>
        </Row>
        {/* darisisni */}
        <Row>
          <Col span={20}>
            <div className="formDevide">
              <label htmlFor="" style={{ fontSize: 10 }}><b>Catatan & Keterangan</b></label><br />
              <TextArea value={notes} onChange={(c) => { setNotes(c.target.value) }} />
            </div>
          </Col>
          <Col span={4}>
            <Button type='primary' danger style={{ marginTop: 17, marginLeft: 7,float:'right' }}
              onClick={async () => {
                // console.log(id,notes);
                // return
                axios
                  .post(`${API_URL}spk/addnote`, {
                    spk_id : id,
                    sec : 'MIXER',
                    note : notes
                  })
                  .then(function (response) {
                    if (response.status == 200) {
                      alert(response.data.msg)
                    }
                  })
                  .catch(function (error) {
                  });
              }}
            >Simpan</Button>
          </Col>
        </Row>
        {/* sampe sini */}
        {
          formulas.map((o, index) => {
            return <Row key={index} style={{ padding: 5, backgroundColor: '#e5e5e5', marginBottom: 1, borderRadius: 5 }}>
              <Col span={12}>{o.nama_item}</Col>
              <Col span={4}>{o.satuan}</Col>
              <Col span={8}>{o.qty}</Col>
            </Row>
          })
        }
      </>
    </div>
  )
}
