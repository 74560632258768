import {
  SearchOutlined, DeleteOutlined, SettingOutlined, EditOutlined
  , PrinterOutlined, EyeOutlined, DownOutlined
} from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { API_URL } from '../../features/global.js'
import { Button, Input, Space, Table, Dropdown, Menu } from 'antd';
// const data = [];
const TableComponent = ({ datas, onEdit, onDelete }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  // const [data,setDatas] = useState(datas)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination) => {
    console.log(pagination);
    setPagination(pagination);
  };
  const menu = (obj) => (
    <Menu onClick={(e) => {
      if (e.key == "Lihat") {
        onEdit(obj)
      } else {
        window.open(`${API_URL}spk/cetkPermintaan?spk_id=${obj.spk_id}&sec=${e.key}`, '_blank');
      }
    }}>
      <Menu.Item key="Lihat"><EyeOutlined /> Lihat</Menu.Item>
      <Menu.Item key="MIXER"><PrinterOutlined /> Mixer</Menu.Item>
      <Menu.Item key="EXTRUDER"><PrinterOutlined /> Extruder</Menu.Item>
      <Menu.Item key="PRINTING"><PrinterOutlined /> Printing</Menu.Item>
      <Menu.Item key="LAMINATING"><PrinterOutlined /> Laminating</Menu.Item>
      <Menu.Item key="PACKING"><PrinterOutlined /> Packing</Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SettingOutlined />
        </div>
      ),
      dataIndex: 'arrowButton',
      key: 'arrowButton',
      width: '5%',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '4px' }}>
          <Button icon={<DeleteOutlined />} type="primary" danger onClick={() => {
            onDelete(record)
          }}></Button>
          <Dropdown overlay={menu(record)}>
            <Button type='primary' primary>
              <Space>
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      ),
    },
    {
      title: 'Nomor SPK',
      dataIndex: 'nomor_spk',
      key: 'nomor_spk',
      ...getColumnSearchProps('nomor_spk'),
    },
    {
      title: 'Nomor SO',
      dataIndex: 'nomor_po',
      key: 'nomor_po',
      ...getColumnSearchProps('nomor_po'),
    },
    {
      title: 'Periode',
      dataIndex: 'tgl',
      key: 'tgl',
      ...getColumnSearchProps('tgl')
    },
    {
      title: 'Product',
      dataIndex: 'nama_product',
      key: 'nama_product',
      ...getColumnSearchProps('nama_product')
    },
    {
      title: 'Qty Produksi',
      dataIndex: 'qty',
      key: 'qty',
      ...getColumnSearchProps('qty')
    },
    {
      title: 'Pembuat',
      dataIndex: 'created_by',
      key: 'created_by',
      ...getColumnSearchProps('created_by')
    },
    {
      title: 'Status',
      dataIndex: 'status_spk',
      key: 'status_spk',
      ...getColumnSearchProps('status_spk')
    }
  ];
  const getRowClassName = (record, index) => {
    // Jika indeks genap, atur kelas CSS 'even-row', jika tidak, atur 'odd-row'
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };
  return (
    <>
      <Table
        columns={columns}
        rowKey="m_partner_id"
        dataSource={datas}
        pagination={pagination}
        rowClassName={getRowClassName}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableComponent;